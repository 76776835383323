import React from 'react';

import { useParams } from 'react-router-dom';

import GetPublishersAndWritersDetails from '../Components/PublishersAndWritersDetails/GetPublishersAndWritersDetails';
import { useSelector } from 'react-redux';
const WritersDetails = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	const params = useParams();

	return (
		<>
			<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='my-5'>
				<GetPublishersAndWritersDetails apiPath='writers' id={params.writerId} endPoint='books' />
			</div>
		</>
	);
};

export default WritersDetails;
