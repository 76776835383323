import React, { useEffect, useState } from 'react';
import style from './AddressForm.module.css';
import { AiOutlinePlus } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import Spinner from '../../Spinner/Spinner';

const AddressForm = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	const mainApi = 'https://dashboard.ilawfair.com';
	const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  	const userData = JSON.parse(window.localStorage.getItem('user'));
const [userCountry, setUserCountry] = useState(userData.country.name);
	useEffect(() => {
		const fetchCountries = async () => {
			const res = await fetch(`${mainApi}/api/countries`, {
				method: 'GET',
				headers: {
					lang: langState,
				},
			});
			const data = await res.json();
			setCountries(data.data);

			setLoading(false);
		};
		fetchCountries();
	}, [langState]);
	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<div dir={langState === 'en' ? 'ltr' : 'rtl'} className={style.addressContainer}>
					<div className='p-3'>
						<h4 className='mb-4'>Shipping addresses</h4>
						<div className={`d-flex align-items-center mb-4 ${style.newAdddress}`}>
							<AiOutlinePlus size={25} />
							<p className='ml-2'>new address</p>
						</div>
						{/*billing*/}
						<div className='mb-4'>
							<h5 className='mb-4'>billing details</h5>
							<label htmlFor='countery' className='d-block'>
								country
							</label>
							<select value={userCountry} name='' id='countery' className={`${style.selectCountry} ${style.inp} mb-4`} onChange={(e) => setUserCountry(e.target.value)}>
								<option value=''>select counter</option>
								{countries.map((country, index) => (
									<option value={country.name} key={index}>
										{country.name}
									</option>
								))}
							</select>
							<label htmlFor='city' className='d-block'>
								city
							</label>
							<select name='' id='city' className={`${style.selectCountry} ${style.inp} mb-4`}>
								<option value=''>select city</option>
								<option>mansoura</option>
								<option value=''>talkha</option>
							</select>
							<label htmlFor='address'>address</label>
							<input type='text' id='address' className={`d-block ${style.inp} mb-4 w-100 `} />
							<label htmlFor='phone'>phone</label>
							<input type='number' id='phone' className={`d-block ${style.inp} mb-4 w-100 `} />
							<label htmlFor='postal'>postal code</label>
							<input type='number' id='postal' className={`d-block ${style.inp} mb-4 w-100 `} />
						</div>
						<button className={style.newAddressBtn}>Add New Address</button>
					</div>
					<div className={style.paymentMethod}>
						<div className='p-3'>
							<h4 className='mb-4'>payment method</h4>
							<div className='d-flex align-items-center gap-2'>
								<AiOutlinePlus size={25} />
								<p>new payment method</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default AddressForm;
