import React from 'react';
import style from './ShowWriterAndPub.module.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Link } from 'react-router-dom';
const ShowWriterAndPub = ({ items, bg, detailsPath, isWriter }) => {
	
	const splideOptions = {
		perPage: 4,
		perMove: 1,
		type: 'loop',
		rewind: true,
		keyboard: 'global',
		gap: '1rem',
		pagination: false,
		padding: '2rem',
		breakpoints: {
			1200: { perPage: 3 },
			991: { perPage: 2.3 },
			768: { perPage: 2 },
			500: { perPage: 1.3 },
			425: { perPage: 1 },
		},
	};
	return (
		<div className={`container-fluid ${bg} py-5`}>
			<Splide options={splideOptions}>
				{items.map((item, index) => {
					return (
						<SplideSlide key={index} className='mb-1'>
							<div className='row align-items-center'>
								<div className={style.imgContainer}>
									<Link to={`${detailsPath}${item.id}`} className={style.publisherName}>
										<img src={item.photo} alt='' className={style.mainImg} />
										<p>{isWriter ? item.name : ''}</p>
									</Link>
								</div>
							</div>
						</SplideSlide>
					);
				})}
			</Splide>
		</div>
	);
};

export default ShowWriterAndPub;
