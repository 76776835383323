import React, { useState, useEffect } from 'react';
import Title from '../../../Utilits/Title/Title';
import Spinner from '../../Spinner/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import ShowBooks from './ShowBooks/ShowBooks';
import { setBooks } from '../../../Redux/AllBooks/BooksSlice';

const GetBooks = ({ title, arabicTitle }) => {
	const [loading, setLoading] = useState(true);

	const langState = useSelector((state) => state.langSlice.lang);
	const searchValue = useSelector((state) => state.BooksSlice.searchValue);
	const dispatch = useDispatch();
	const mainApi = searchValue ? `https://dashboard.ilawfair.com/api/books?search=${searchValue}` : `https://dashboard.ilawfair.com/api/books`;
	// fetching books
	useEffect(() => {
		const getBooks = async () => {
			const res = await fetch(mainApi, {
				method: 'GET',
				headers: {
					lang: langState,
				},
			});
			const data = await res.json();
			dispatch(setBooks(data.data));

			setLoading(false);
		};
		getBooks();
	}, [langState, searchValue, dispatch]);
	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<div className='my-5'>
					<Title title={title} arabicTitle={arabicTitle} />
					<ShowBooks />
				</div>
			)}
		</>
	);
};

export default GetBooks;
