import React, { useEffect, useState } from 'react';
import style from './ContactUsForm.module.css';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const ContactUsForm = () => {
	const [name, setName] = useState('');
	const [mail, setMail] = useState('');
	const [msg, setMsg] = useState('');
	const [title, setTitle] = useState('');
	const [company, setCompany] = useState('');
	const [phone, setPhone] = useState('');
	const [country, setCountry] = useState('');
	const [subject, setSubject] = useState('');
	const navegate = useNavigate();
	const langState = useSelector((state) => state.langSlice.lang);
	const mainApi = 'https://dashboard.ilawfair.com';

	const SendData = (e) => {
		e.preventDefault();
		const data = {
			name,
			mail,
			title,
			company,
			msg,
			phone,
			country,
			subject,
		};
		fetch(`${mainApi}/api/sendContactMessage`, {
			method: 'POST',
			headers: {
				lang: langState,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then((data) => data.json())
			.then((res) => {
				if (res.status) {
					setName('');
					setMail('');
					setCompany('');
					setCountry('');
					setPhone('');
					setTitle('');
					setMsg('');
					setPhone('');
            	Swal.fire({
					position: 'top-end',
					icon: 'success',
					title: `Your Data Sent successfully`,
					showConfirmButton: false,
					timer: 1500,
				});
					setTimeout(() => {
						navegate('/');
					}, 2000);
				} else {
            Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Something Went Wrong , please try again',
			});
				}
			});
	};

	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='my-5 py-5 '>
			<h6 className='mb-4 fs-5 text-center'>tell us your message</h6>
			<form action='' className='w-100 ms-auto' onSubmit={(e) => SendData(e)}>
				<div className={`d-flex justify-content-between align-items-center mb-5 w-100 ${style.mainContainer}`}>
					{/*name input*/}
					<div className={style.inpContainer}>
						<label htmlFor='name' className='fw-bold d-block'>
							name
						</label>
						<input className={style.inp} required type='text' id='name' placeholder='Name' onChange={(e) => setName(e.target.value)} />
					</div>
					{/*email input*/}
					<div className={style.inpContainer}>
						<label htmlFor='email' className='fw-bold d-block'>
							Email Address
						</label>
						<input className={style.inp} required type='email' id='email' placeholder='Name' onChange={(e) => setMail(e.target.value)} />
					</div>
				</div>
				<div className={`d-flex justify-content-between align-items-center mb-5 w-100 ${style.mainContainer}`}>
					{/*Company name input*/}
					<div className={style.inpContainer}>
						<label htmlFor='company' className='fw-bold d-block'>
							Company name
						</label>
						<input className={style.inp} required type='text' id='company' placeholder='Name' onChange={(e) => setCompany(e.target.value)} />
					</div>
					{/*country input*/}
					<div className={style.inpContainer}>
						<label htmlFor='Country' className='fw-bold d-block'>
							Country
						</label>
						<select className={style.inp} required name='Country' id='Country' onChange={(e) => setCompany(e.target.value)}>
							<option value=''>Select your country</option>
							<option value='egypt'>Egypt</option>
							<option value='united arab'>United Arab Emaratis</option>
						</select>
					</div>
				</div>
				{/*phone input*/}
				<label htmlFor='phone' className='fw-bold d-block'>
					Phone
				</label>
				<input required type='number' id='phone' className={`d-block mb-5 ${style.inp} w-100`} placeholder='enter your phone' onChange={(e) => setPhone(e.target.value)} />
				{/*title input*/}
				<label htmlFor='title' className='fw-bold d-block'>
					inputs.title
				</label>
				<input required onChange={(e) => setTitle(e.target.value)} type='text' id='title' className={`d-block mb-5 w-100 ${style.inp}`} placeholder='inputs.title' />
				<label htmlFor='subject' className='fw-bold d-block'>
					Select subject
				</label>
				<select required id='subject' className={`mb-5 d-block w-100 ${style.inp}`} onChange={(e) => setSubject(e.target.value)}>
					<option value=''>Select Subject</option>
					<option value='Questions'>Questions</option>
					<option value='suggest'>suggest</option>
					<option value='request'>request</option>
					<option value='complaint'>complaint</option>
				</select>
				{/*text area*/}
				<label htmlFor='message' className='fw-bold d-block'>
					your message
				</label>
				<textarea className={`d-block w-100 mb-5 ${style.msgArea}`} required onChange={(e) => setMsg(e.target.value)} id='message' cols='60' rows='7' placeholder='enter something'></textarea>
				<div className='d-flex justify-content-center align-items-center gap-5'>
					<input className={style.submitBtn} type='submit' value={langState === 'en' ? 'Send' : 'إرسال'} />
					<input className={style.resetBtn} type='reset' value={langState === 'en' ? 'Rest' : 'تفريغ'} />
				</div>
			</form>
		</div>
	);
};

export default ContactUsForm;
