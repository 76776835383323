import React from 'react';
import style from './ContactInformation.module.css';
import { AiFillMail } from 'react-icons/ai';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
const ContactInformation = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='text-center'>
			<h5 className='my-5'>Contact Information</h5>
			<p className='my-5'>We will answer any questions you may have about our online sales, rights or partnership service right here.</p>
			<div className='w-100 ms-auto my-5'>
				<div className={`d-flex align-items-center justify-content-center gap-1 mb-2 ${style.info}`}>
					<AiFillMail size={20} />
					<p>info@ilawfair.com</p>
				</div>
				<div className={`d-flex align-items-center justify-content-center gap-1 mb-2 ${style.info}`}>
					<BsFillTelephoneFill size={20} />
					<p>971564093620</p>
				</div>
			</div>
		</div>
	);
};

export default ContactInformation;
