import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import style from './BooksTable.module.css';
const BooksTable = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	const books = useSelector((state) => state.BooksSlice.books);
	console.log(books);
	return (
		<>
			<h5 className='mb-5  fw-bold'>Books</h5>
			<table dir={langState === 'en' ? 'ltr' : 'rtl'} className={`table ${style.borderTable}`}>
				<thead className={style.border}>
					<tr>
						<th scope='col'>Book</th>
						<th scope='col'>Publisher</th>
						<th scope='col'>Author</th>
						<th scope='col'>Type</th>
						<th scope='col'>Action</th>
					</tr>
				</thead>
				<tbody>
					{books.map((book, index) => (
						<tr key={index}>
							<td className={style.row}>{book.name}</td>
							<td className={style.row}>{book.publisher.name}</td>
							<td className={style.row}>{book.writer.name}</td>
							<td className={style.row}>
								<p className={style.colorRed}>{book.pdfCopy.status === 1 ? '- PDF' : ''}</p>
								<p className={style.colorRed}>{book.hardCopy.status === 1 ? '- Hard' : ''}</p>
							</td>
							<td className={style.row}>
								<Link className={style.link} to={`/book/${book.id}`}>
									View
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
};

export default BooksTable;
