import React from 'react';
import style from './CartItems.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineMinus, AiOutlinePlus, AiOutlineCloseCircle } from 'react-icons/ai';
import { increaseQTY, decreaseQTY, removeFromCart } from '../../../Redux/CartState/CartSlice';
const CartItems = ({ items }) => {
	console.log(items);
	const currencyState = useSelector((state) => state.currencySlice.currency);
	const dispatch = useDispatch();
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='my-5 p-4'>
			{items.map((item, index) => (
				<div className={`row position-relative align-items-center mb-5 ${style.mainContainer}`} key={index}>
					<div className='col-12 col-md-6 mb-3 mb-md-0'>
						<img src={item.img} alt='' className={style.mainImg} />
					</div>
					<div className='col-12 col-md-6 mb-3 mb-md-3'>
						<p className='text-center fw-bold mb-2'>
							{item.quantity} * {item.name}
						</p>
						<p className={`text-center mb-2 ${style.price}`}>
							{item.price} {currencyState}
						</p>
						<p className='text-center'>{item.text}</p>
					</div>
					<div className='col-12 col-md-6 mb-3'>
						<div className='d-flex  flex-row align-items-center justify-content-center gap-2'>
							<div className={style.box} onClick={() => dispatch(decreaseQTY(item))}>
								<AiOutlineMinus />
							</div>
							<div className={style.box}>
								<p>{item.quantity}</p>
							</div>
							<div className={style.box} onClick={() => dispatch(increaseQTY(item))}>
								<AiOutlinePlus />
							</div>
						</div>
					</div>
					<div className={`col-12 col-md-6 my-3 ${style.totalPriceContainer}`}>
						<p className={`text-center text-md-end fw-bold ${item.totalPrice}`}>
							Total : {item.price * item.quantity} {currencyState}
						</p>
					</div>
					<div onClick={() => dispatch(removeFromCart(item))} className={style.deleteIcon}>
						<AiOutlineCloseCircle />
					</div>
				</div>
			))}
		</div>
	);
};

export default CartItems;
