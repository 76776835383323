import React, { useEffect, useRef, useState } from 'react';
import style from './AuthSideBar.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { closeForm } from '../../../Redux/authState/loginSlice.js';
import Login from './Forms/Login/Login';
import SignUp from './Forms/SignUp/SignUp';
import ResetPassword from './Forms/ResetPassword/ResetPassword';
const AuthSideBar = () => {
	const authSideBar = useRef(null);
	const langState = useSelector((state) => state.langSlice.lang);
	const isFormOpen = useSelector((state) => state.loginSlice.openForm);
	const dispatch = useDispatch();
	const [newAccount, setNewAccount] = useState(false);
	const [login, setLogin] = useState(true);
	const [resetPassword, setResetPassword] = useState(false);

	// if user click anywhere outside the side bar . the side bar will close
	// useEffect(() => {
	// 	const handleOutSideClick = (e) => {
	// 		if (authSideBar.current && !authSideBar.current.contains(e.target)) {
	// 			dispatch(closeForm(false));
	// 		}
	// 	};
	// 	document.addEventListener('mousedown', handleOutSideClick);
	// 	return () => {
	// 		document.removeEventListener('mousedown', handleOutSideClick);
	// 	};
	// }, [dispatch, isFormOpen]);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} ref={authSideBar} className={`${style.mainContainer} ${isFormOpen ? style.show : style.hide}`}>
			<>
				<div className='p-3'>{newAccount && !login && !resetPassword && <SignUp isSideBar={true} setNewAccount={setNewAccount} setLogin={setLogin} />}</div>
				<div className='p-3'>{!newAccount && login && !resetPassword && <Login isSideBar={true} setNewAccount={setNewAccount} setLogin={setLogin} setResetPassword={setResetPassword} />}</div>
				<div className='p-3'>
					{!newAccount && !login && resetPassword && (
						<ResetPassword isSideBar={true} setNewAccount={setNewAccount} setLogin={setLogin} setResetPassword={setResetPassword}  />
					)}
				</div>
			</>
		</div>
	);
};

export default AuthSideBar;
