import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	category: false,
	categoryItems: [],
};
const categorySlice = createSlice({
	name: 'categorySlice',
	initialState,
	reducers: {
		openCategory: (state, action) => {
			state.category = action.payload;
		},
		closeCategory: (state, action) => {
			state.category = action.payload;
		},
		addToCategories: (state, action) => {
			state.categoryItems = action.payload;
		},
	},
});
export const { openCategory, closeCategory, addToCategories } = categorySlice.actions;
export default categorySlice.reducer;
