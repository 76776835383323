import React from 'react';
import style from './PagesMenu.module.css';
import { MdArrowBackIosNew } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { closeCategory } from '../../../../../../Redux/CategoryListState/CategoryListSlice';

const PagesMenu = ({ openPageMenu, setOpenPagseMenu, setOpenShopMenu }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div>
			<div onClick={() => setOpenPagseMenu(false)} className={`${style.listContainer} my-2 py-1`}>
				<div className={`p-3  ${style.list}`}>
					<MdArrowBackIosNew /> {langState === 'en' ? 'Back' : 'الرجوع'}
				</div>
			</div>
			<div
				onClick={() => {
					navigate('/');
					dispatch(closeCategory());
				}}
				className={`${style.listContainer} my-2 py-1`}
			>
				<Link to='/' className={`p-3  ${style.list} ${style.link}`}>
					{langState === 'en' ? 'Home' : 'الرئيسية'}
				</Link>
			</div>
			<div className={`${style.listContainer} my-2 py-1`}>
				<Link
					onClick={() => {
						setOpenShopMenu(true);
						setOpenPagseMenu(false);
					}}
					className={`px-3 d-flex justify-content-between ${style.link} ${style.list}`}
				>
					<p>{langState === 'en' ? 'Shop' : 'المتجر'}</p>
					<IoIosArrowForward />
				</Link>
			</div>
			<div
				onClick={() => {
					navigate('/about');
					dispatch(closeCategory());
				}}
				className={`${style.listContainer} my-2 py-1`}
			>
				<Link to='/about' className={`p-3  ${style.list} ${style.link}`}>
					{langState === 'en' ? 'About' : 'من نحن'}
				</Link>
			</div>
			<div
				onClick={() => {
					navigate('/faq');
					dispatch(closeCategory());
				}}
				className={`${style.listContainer} my-2 py-1`}
			>
				<Link to='/faq' className={`p-3  ${style.list} ${style.link}`}>
					{langState === 'en' ? 'FAQS' : ' الاسئلة الشائعة'}
				</Link>
			</div>
			<div
				onClick={() => {
					navigate('/contact');
					dispatch(closeCategory());
				}}
				className={`${style.listContainer} my-2 py-1`}
			>
				<Link to='/contact' className={`p-3  ${style.list} ${style.link}`}>
					{langState === 'en' ? 'Contact us' : '  تواصل معنا'}
				</Link>
			</div>
		</div>
	);
};

export default PagesMenu;
/*
  	{openShopMenu ? (
				<ShopMenu openShopMenu={openShopMenu} setOpenShopMenu={setOpenShopMenu} />
			) : (
				<ul className={`${openPageMenu ? style.show : style.hide} py-4`}>
					<li onClick={() => setOpenPageMenu(false)} className={`${style.backIcon} d-flex align-items-center gap-2 mb-5 p-2`}>
						<MdArrowBackIosNew />
						<span>Back</span>
					</li>
					<li onClick={() => dispatch(closeCategory())} className='mb-3'>
						<Link className={`${style.link} mb-2`} to='/'>
							Home
						</Link>
					</li>
					<li onClick={() => setOpenShopMenu(true)} className={`mb-3  d-flex justify-content-between align-items-center ${style.icon}`}>
						<span>Shop</span>
						<MdOutlineArrowForwardIos />
					</li>
					<li onClick={() => dispatch(closeCategory())} className='mb-3'>
						<Link className={`${style.link} mb-2`} to='/about'>
							About
						</Link>
					</li>
					<li onClick={() => dispatch(closeCategory())} className='mb-3'>
						<Link className={`${style.link} mb-2`} to='/faq'>
							FAQS
						</Link>
					</li>
					<li onClick={() => dispatch(closeCategory())} className='mb-3'>
						<Link className={`${style.link} mb-2`} to='/contact'>
							Contact
						</Link>
					</li>
				</ul>
			)}
*/
