import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	openForm: false,
	isLogin: JSON.parse(localStorage.getItem('auth')) || false,
	user: JSON.parse(localStorage.getItem('user')) || [],
};
const loginSlice = createSlice({
	name: 'loginSlice',
	initialState,
	reducers: {
		openForm: (state, action) => {
			state.openForm = action.payload;
		},
		closeForm: (state, action) => {
			state.openForm = action.payload;
		},
		login: (state) => {
			state.isLogin = true;
			window.localStorage.setItem('auth', JSON.stringify(state.isLogin));
			// window.localStorage.setItem('auth', JSON.stringify(state.isLogin));
		},
		logOut: (state) => {
			state.isLogin = false;
			state.user = [];
			window.localStorage.setItem('user', JSON.stringify(state.user));
			window.localStorage.setItem('auth', JSON.stringify(state.isLogin));
			// window.localStorage.setItem('auth', JSON.stringify(state.isLogin));
		},
		// userData
		updateUserData: (state, action) => {
			state.user = action.payload;
			// update local storage
			window.localStorage.setItem('user', JSON.stringify(state.user));
		},
	},
});
export const { login, logOut, openForm, closeForm, updateUserData } = loginSlice.actions;
export default loginSlice.reducer;
