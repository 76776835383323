import React, { useEffect } from 'react';
import GetBooks from '../Components/HomePage Component/GetBooks/GetBooks';
import Carousel from '../Components/HomePage Component/Carousel/Carousel';
import GetWriteAndPub from '../Components/HomePage Component/GetWriter&pub/GetWriteAndPub';
import Meta from '../Components/Meta/Meta.jsx';
import { useSelector } from 'react-redux';

const Home = () => {
	const langState = useSelector((state) => state.langSlice.lang);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	// fetching countries

	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
			<Meta title='iLaw | Home Page' content='multi vendor book store we have more than 5000 books at the differant categories' />
			<Carousel />
			<GetBooks title='best salleing books' arabicTitle='الكتب الأكثر مبيعا' />
			<GetBooks title='newest' arabicTitle='الاحدث اصدارا' />
			<GetWriteAndPub title='publishers' arabicTitle='الناشرين' apiPath='/publishers' bg='bg-light' detailsPath='/publisher/' isWriter={false} />
			<GetWriteAndPub title='authors' arabicTitle='المؤلفون' apiPath='/writers' bg='bg-white' detailsPath='/writer/' isWriter={true} />
		</div>
	);
};

export default Home;
