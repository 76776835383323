import React, { useState } from 'react';
import style from './orderDetails.module.css';
// down
import { MdKeyboardArrowDown } from 'react-icons/md';
// top
import { MdKeyboardArrowUp } from 'react-icons/md';
import { useSelector } from 'react-redux';
const OrderDetails = () => {
	const [showOrder, setShowOrder] = useState(false);
	const [showTotal, setShowTotal] = useState(false);
	const [showShipping, setShowShipping] = useState(false);
	const [showPayment, setShowPayment] = useState(false);
	const cartItemsState = useSelector((state) => state.cartSlice.cartItems);
	const shippingCoast = 250;
	const discount = 50;
	const langState = useSelector((state) => state.langSlice.lang);
	const totalPrice = cartItemsState.reduce((acc, book) => {
		acc += book.quantity * book.price;
		return acc;
	}, 0);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className={style.detailsContainer}>
			<div className='p-3'>
				<div className='d-flex justify-content-between align-items-center'>
					<p className='fw-bold fs-6'>your order</p>
					{showOrder ? <MdKeyboardArrowUp size={25} onClick={() => setShowOrder(false)} /> : <MdKeyboardArrowDown onClick={() => setShowOrder(true)} size={25} />}
				</div>
				{showOrder
					? cartItemsState.map((item, index) => {
							return (
								<div className='mb-4 d-flex justify-content-between align-items-center' key={index}>
									<p>
										{item.quantity} * {item.name}
									</p>
									<p>{item.quantity * item.price}</p>
								</div>
							);
					  })
					: ''}
			</div>
			{/*total container*/}
			<div className={style.totalContainer}>
				<div className='p-3'>
					<div className='d-flex justify-content-between align-items-center'>
						<p className='fw-bold fs-6'>total</p>
						{showTotal ? <MdKeyboardArrowUp size={25} onClick={() => setShowTotal(false)} /> : <MdKeyboardArrowDown onClick={() => setShowTotal(true)} size={25} />}
					</div>
					{showTotal ? (
						<div className='d-flex justify-content-between align-items-center'>
							<p className='fw-bold'>Subtotal : </p>
							<p>{totalPrice}</p>
						</div>
					) : (
						''
					)}
				</div>
			</div>
			{/*shipping container*/}
			<div className={style.shippingContainer}>
				<div className='p-3'>
					<div className='d-flex justify-content-between align-items-center'>
						<p className='fw-bold fs-6'>Shipping</p>
						{showShipping ? <MdKeyboardArrowUp size={25} onClick={() => setShowShipping(false)} /> : <MdKeyboardArrowDown onClick={() => setShowShipping(true)} size={25} />}
					</div>
					{showShipping ? (
						<>
							<div className='d-flex justify-content-between align-items-center mb-3'>
								<div>
									<input type='radio' id='aramex' name='shipping' className='mr-2 d-inline-block' />
									<label htmlFor='aramex'>Exprese Shipping (Aramex)</label>
								</div>
								<p>{shippingCoast}</p>
							</div>
							<div className='d-flex justify-content-between align-items-center mb-3'>
								<div>
									<input type='radio' id='Fedex' name='shipping' className='mr-2 d-inline-block' />
									<label htmlFor='Fedex'>Fedex</label>
								</div>
								<p>{shippingCoast}</p>
							</div>
						</>
					) : (
						''
					)}
				</div>
			</div>
			{/*reset container*/}
			<div className={style.resetContainer}>
				<div className='p-3'>
					<div className='d-flex justify-content-between align-items-center mb-3'>
						<p className='fw-bold'>Total</p>
						<p>{totalPrice}</p>
					</div>
					<div className='d-flex justify-content-between align-items-center mb-3'>
						<p className='fw-bold'>Discount</p>
						<p>{discount}</p>
					</div>
					<div className='d-flex justify-content-between align-items-center mb-3'>
						<p className='fw-bold'>Shipping coast</p>
						<p>{shippingCoast}</p>
					</div>
					<div className='d-flex justify-content-between align-items-center mb-3'>
						<p className='fw-bold'>Net Price</p>
						<p>{totalPrice + (shippingCoast - discount)}</p>
					</div>
				</div>
			</div>
			{/*payment container*/}
			<div className={style.paymentContainer}>
				<div className='p-3'>
					<div className='d-flex justify-content-between align-items-center'>
						<p className='fw-bold fs-6'>Payment</p>
						{showPayment ? <MdKeyboardArrowUp size={25} onClick={() => setShowPayment(false)} /> : <MdKeyboardArrowDown onClick={() => setShowPayment(true)} size={25} />}
					</div>
					{showPayment ? (
						<>
							<input type='radio' id='visa' />
							<label className='mb-5 mx-2' htmlFor='visa'>
								Visa/Master card
							</label>
						</>
					) : (
						''
					)}
				</div>
			</div>
			<button className={style.mainBtn}>Place order</button>
		</div>
	);
};

export default OrderDetails;
