import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// import bootstrap fiels
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
// import redux store ans provider
import store from './Redux/Store/store';
import { Provider } from 'react-redux';

// import react router tools
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './Pages/Home';
import LayOut from './Components/SharedLayout/LayOut';
import BookDetails from './Pages/BookDetails';
import PublisherDetails from './Pages/PublisherDetails';
import WritersDetails from './Pages/WritersDetails';
import Cart from './Pages/Cart';
import Checkout from './Pages/Checkout';
import Faq from './Pages/Faq';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Publishers from './Pages/Publishers';
import Writers from './Pages/Writers';
import AccountDetails from './Pages/AccountDetails';
import Books from './Pages/Books';
import Dashboard from './Pages/Dahsboard';
import Orders from './Pages/Orders';
import Whishlist from './Pages/Whishlist';
const routes = createBrowserRouter([
	{
		path: '/',
		element: <LayOut />,
		children: [
			{ index: true, element: <Home /> },
			{ path: '/book/:bookId', element: <BookDetails /> },
			{ path: '/publisher', element: <Publishers /> },
			{ path: '/publisher/:publisherId', element: <PublisherDetails /> },
			{ path: '/writer', element: <Writers /> },
			{ path: '/writer/:writerId', element: <WritersDetails /> },
			{ path: 'cart', element: <Cart /> },
			{ path: 'checkout', element: <Checkout /> },
			{ path: 'faq', element: <Faq /> },
			{ path: 'about', element: <About /> },
			{ path: 'contact', element: <Contact /> },
			{ path: 'accountdetails', element: <AccountDetails /> },
			{ path: '/books', element: <Books /> },
			{ path: '/dashboard', element: <Dashboard /> },
			{ path: '/orders', element: <Orders /> },
			{ path: '/wishlist', element: <Whishlist />  },
		],
	},
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store={store}>
	
			<RouterProvider router={routes}></RouterProvider>
		</Provider>
	</React.StrictMode>
);
