import React from 'react';
import style from './Title.module.css';
import { useSelector } from 'react-redux';
const Title = ({ title, arabicTitle }) => {
	const lang = useSelector((state) => state.langSlice.lang);
	return (
		<div className='text-center py-5'>
			<h2 className={`text-uppercase ${style.title}`}>{lang === 'en' ? title : arabicTitle}</h2>
		</div>
	);
};

export default Title;
