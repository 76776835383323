import React from 'react';
import style from './HandleAuth.module.css';
import { CiUser } from 'react-icons/ci';
import { useDispatch, useSelector } from 'react-redux';
import { login, logOut, openForm } from '../../../../Redux/authState/loginSlice.js';
const HandleAuth = () => {
	const dispatch = useDispatch();
	const langState = useSelector((state) => state.langSlice.lang);

	const loginState = useSelector((state) => state.loginSlice.isLogin);

	const handleApperance = () => {
		if (loginState && langState === 'en') {
			return (
				<div className={` d-none d-md-block ${style.main}`} onClick={() => dispatch(logOut())} size={20}>
					<CiUser className={style.icon} /> log out
				</div>
			);
		} else if (loginState && langState === 'ar') {
			return (
				<div className={` d-none d-md-block ${style.main}`} onClick={() => dispatch(logOut())} size={20}>
					<CiUser className={style.icon} /> تسجيل الخروج
				</div>
			);
		} else if (!loginState && langState === 'en') {
			return (
				<div className={` d-none d-md-block ${style.main}`} onClick={() => dispatch(openForm(true))}>
					<CiUser className={style.icon} size={20} /> log in
				</div>
			);
		} else if (loginState === false && langState === 'ar') {
			<div className={` d-none d-md-block  ${style.main}`} onClick={() => dispatch(openForm(true))}>
				<CiUser className={style.icon} size={20} /> تسجيل الدخول
			</div>;
		}
	};
	return <div className=''>{handleApperance()}</div>;
};

export default HandleAuth;
