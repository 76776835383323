import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	books: JSON.parse(window.localStorage.getItem('books')) || [],
	searchValue: '',
	selectValue: '',
};
const BookSlice = createSlice({
	name: 'BookSlice',
	initialState,
	reducers: {
		setBooks: (state, action) => {
			state.books = action.payload;
			window.localStorage.setItem('books', JSON.stringify(state.books));
		},
		setSearchValue: (state, action) => {
			state.searchValue = action.payload;
		},
		setSelectValue: (state, action) => {
			state.selectValue = action.payload;
		},
	},
});
export const { setBooks, setSearchValue, setSelectValue } = BookSlice.actions;
export default BookSlice.reducer;
