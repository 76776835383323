import React from 'react';
import style from './WishlistDetails.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeFromWhishList } from '../../../Redux/Whishlist/WhishlistSlice';
const WishlistDetails = () => {
	const wishListItems = useSelector((state) => state.WhishlistSlice.whishListItems);
	const dispatch = useDispatch();
	console.log(wishListItems);
	return (
		<div className=''>
			<h5 className='text-center  fw-bold mb-5'>Wishlist</h5>
			{wishListItems.length > 0 ? (
				<table className='table'>
					<thead>
						<tr>
							<th scope='col'>Image</th>
							<th scope='col'>Book</th>
							<th scope='col'>Publisher</th>
							<th scope='col'>Author</th>
							<th scope='col'>Type</th>
							<th scope='col'>Action</th>
						</tr>
					</thead>
					<tbody>
						{wishListItems.map((item, index) => (
							<tr key={index}>
								<td className={style.row}>
									<img className={style.img} src={item.photo} alt='' />
								</td>
								<td className={style.row}>{item.name}</td>
								<td className={style.row}>{item.publisher.name}</td>
								<td className={style.row}>{item.writer.name}</td>
								<td className={style.row}>
									<p className={style.colorRed}>{item.pdfCopy.status === 1 ? '- PDF' : ''}</p>
									<p className={style.colorRed}>{item.hardCopy.status === 1 ? '- Hard' : ''}</p>
								</td>
								<td className={`${style.row}`}>
									<Link className={`${style.link} mb-2`} to={`/book/${item.id}`}>
										View
									</Link>
									<button onClick={() => dispatch(removeFromWhishList(item))} className={style.link}>
										Delete
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<div className='d-flex justify-content-center align-items-center'>
					<h5 className='fw-bold fs-4'>No Items in Wishlist</h5>
				</div>
			)}
		</div>
	);
};

export default WishlistDetails;
