import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	currency: JSON.parse(window.localStorage.getItem('currency')) || 'USD',
};
const currencySlice = createSlice({
	name: 'currencySlice',
	initialState,
	reducers: {
		changeCurrency: (state, action) => {
			state.currency = action.payload;
			// update localStorage
			window.localStorage.setItem('currency', JSON.stringify(state.currency));
		},
	},
});
export const { changeCurrency } = currencySlice.actions;
export default currencySlice.reducer;
