import React from 'react';
import CartTable from './CartTable/CartTable';
import Copon from './CoponDetails/Copon';
import { useSelector } from 'react-redux';
const CartDetails = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='container-fluid row align-items-center gap-2'>
			<div className='col-12  col-md-7 mb-md-0'>
				<CartTable />
			</div>
			<div className='col-12   col-md-4'>
				<Copon />
			</div>
		</div>
	);
};

export default CartDetails;
