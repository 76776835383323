import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import style from './DashBoardSideBar.module.css';
import { Link } from 'react-router-dom';
import { logOut } from '../../../Redux/authState/loginSlice';
const DashBoardSideBar = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	const dispatch = useDispatch();

	return (
		<div className={`${style.sideBarContainer}`}>
			<h4 className='fw-bold text-center  mb-5'>{langState === 'en' ? 'My Account' : 'حسابي'}</h4>
			<ul className='text-center'>
				<li className={`mb-3 ${style.list}`}>
					<Link className={style.link} to='/dashboard'>
						Dashboard
					</Link>
				</li>
				<li className={`mb-3 ${style.list}`}>
					<Link className={style.link} to='/orders'>
						Orders
					</Link>
				</li>
				<li className={`mb-3 ${style.list}`}>
					<Link className={style.link} to='/books'>
						Books
					</Link>
				</li>
				<li className={`mb-3 ${style.list}`}>
					<Link className={style.link} to='addressess'>
						Addresses
					</Link>
				</li>
				<li className={`mb-3 ${style.list}`}>
					<Link className={style.link} to='/accountdetails'>
						Account Details
					</Link>
				</li>
				<li className={`mb-3 ${style.list}`}>
					<Link className={style.link} to='/wishlist'>
						Wishlist
					</Link>
				</li>
				<li className={`mb-3 ${style.list}`}>
					<Link className={style.link} to='/paymentmethods'>
						Payment Methods
					</Link>
				</li>
				<li className={`mb-3 ${style.logOut} ${style.list}`} onClick={() => dispatch(logOut())}>
					Log out
				</li>
			</ul>
		</div>
	);
};

export default DashBoardSideBar;
