import React, { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import style from './SearchBar.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchValue, setSelectValue } from '../../../../Redux/AllBooks/BooksSlice.js';
import Spinner from '../../../Spinner/Spinner';
import { addToCategories } from '../../../../Redux/CategoryListState/CategoryListSlice.js';
const SearchBar = () => {
	const dispatch = useDispatch();
	const langState = useSelector((state) => state.langSlice.lang);
	const mainApi = 'https://dashboard.ilawfair.com';
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchCategories = async () => {
			const res = await fetch(`${mainApi}/api/sections`, {
				method: 'GET',
				headers: {
					lang: langState,
					section: 0,
				},
			});
			const data = await res.json();
			setCategories(data);
			dispatch(addToCategories(data));
			setLoading(false);
		};
		fetchCategories();
	}, [langState]);
	const handleInputChange = (e) => {
		dispatch(setSearchValue(e.target.value));
	};
	const handleSelectChange = (e) => {
		dispatch(setSelectValue(e.target.value));
	};
	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<div className={`d-flex row ${style.mainContainer}`}>
					{/*search input*/}
					<input onChange={(e) => handleInputChange(e)} type='text' className={`${style.searchInp} col-6`} placeholder='Search For Books' />
					<select onChange={(e) => handleSelectChange(e)} className={`col-4 text-center ${style.selctInp}`}>
						<option value=''>All Categories</option>
						{categories.map((category, index) => (
							<option value={langState === 'en' ? category.name_en : category.name_ar} key={index}>
								{langState === 'en' ? category.name_en : category.name_ar}
							</option>
						))}
					</select>
					<div className={`${style.iconContainer} text-center col-2`}>
						<AiOutlineSearch />
					</div>
				</div>
			)}
		</>
	);
};

export default SearchBar;
