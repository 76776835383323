import React from 'react';
import style from './LanguageMenu.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { MdArrowBackIosNew } from 'react-icons/md';
import { closeCategory } from '../../../../../../Redux/CategoryListState/CategoryListSlice';
import { changeLang } from '../../../../../../Redux/langState/langSlice.js';
const LanguageMenu = ({ setOpenLanguagesMenu }) => {
	const langState = useSelector((state) => state.langSlice.lang);
	const dispatch = useDispatch();
	return (
		<div>
			<div onClick={() => setOpenLanguagesMenu(false)} className={`${style.listContainer} my-2 py-1`}>
				<div className={`p-3  ${style.list}`}>
					<MdArrowBackIosNew /> {langState === 'en' ? 'Back' : 'الرجوع'}
				</div>
			</div>
			<div className={`${style.listContainer} my-2 py-1`}>
				<div
					onClick={() => {
						dispatch(changeLang('en'));
						dispatch(closeCategory());
					}}
					className={`p-3  ${style.list}`}
				>
					<p>{langState === 'en' ? 'English' : 'الإنجليزية'}</p>
				</div>
			</div>
			<div className={`${style.listContainer} my-2 py-1`}>
				<div
					onClick={() => {
						dispatch(changeLang('ar'));
						dispatch(closeCategory());
					}}
					className={`p-3 d-flex justify-content-between ${style.list}`}
				>
					<p>{langState === 'en' ? 'Arabic' : 'العربية'}</p>
				</div>
			</div>
			<div className={`${style.listContainer} my-2 py-1`}>
				<div
					onClick={() => {
						dispatch(changeLang('fr'));
						dispatch(closeCategory());
					}}
					className={`p-3 d-flex justify-content-between ${style.list}`}
				>
					<p>{langState === 'en' ? 'French' : 'الفرنسية'}</p>
				</div>
			</div>
		</div>
	);
};

export default LanguageMenu;
