import React from 'react';
import { Outlet } from 'react-router-dom';
import SettingsHeader from './SettingHeader/SettingHeader';
import MainHeader from './mainHeader/MainHeader';
import SubHeader from './SubHeader/SubHeader';
import Footer from './Footer/Footer';
import style from './LayOut.module.css';
import { useSelector } from 'react-redux';
const LayOut = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
			<SettingsHeader />
			<MainHeader />
			<SubHeader />
			<div className={style.main}>
				<Outlet />
			</div>
			<Footer />
		</div>
	);
};

export default LayOut;
