import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineMenu } from 'react-icons/ai';
import style from './BrowseCategory.module.css';
import { openCategory } from '../../../../Redux/CategoryListState/CategoryListSlice.js';
import CategorySideBar from './CategorySideBar/CategorySideBar';
const BrwoseCategory = () => {
	const dispatch = useDispatch();
	const langState = useSelector((state) => state.langSlice.lang);
	const categoryState = useSelector((state) => state.CategorySlice.category);
	return (
		<div>
			<div className={`d-flex gap-2 p-2  ${style.mainContainer}`} onClick={() => dispatch(openCategory(true))}>
				<AiOutlineMenu size={30} />
				<p className='fs-5'>{langState === 'en' ? 'browse categories' : 'تصفح الأقسام'}</p>
			</div>
			{/*overlay*/}
			{categoryState ? <div className={style.overLay}></div> : ''}

			{/* categorySideBar*/}
			<CategorySideBar />
		</div>
	);
};

export default BrwoseCategory;
