import React from 'react';
import HandleAuth from './handleAuth/HandleAuth';
import SelectCurrency from './selectCurrency/SelectCurrency';
import SelectLang from './selectLang/SelectLang';
import style from './SettingHeader.module.css';
import { useSelector } from 'react-redux';
import TrackOrder from './TrackOrder/TrackOrder';

const SettingHeader = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className={`d-flex justify-content-center  py-3 gap-4 ${style.border}`}>
			<TrackOrder />
			<SelectLang />
			<SelectCurrency />
			<HandleAuth />
		</div>
	);
};

export default SettingHeader;
/*
lang list : 
  <div id='basicDropdownHover1' className='dropdown-menu dropdown-unfold right-0 left-auto' aria-labelledby='basicDropdownHoverInvoker1'>
										<a className='dropdown-item active' href='/#'>
											Tamil
										</a>
										<a className='dropdown-item' href='/#'>
											Arabic
										</a>
										<a className='dropdown-item' href='/#'>
											French
										</a>
									</div>
*/
/*

*/
/*
  currency List 
  <div id='basicDropdownHover' className='dropdown-menu dropdown-unfold right-0 left-auto' aria-labelledby='basicDropdownHoverInvoker'>
										<a className='dropdown-item active' href='/#'>
											INR
										</a>
										<a className='dropdown-item' href='/#'>
											Euro
										</a>
										<a className='dropdown-item' href='/#'>
											Yen
										</a>
									</div>
*/
