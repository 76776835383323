import React from 'react';
import DashBoardSideBar from '../Components/Dashboard/SideBar/DashBoardSideBar';
import Meta from '../Components/Meta/Meta.jsx';
import { useSelector } from 'react-redux';
import DashboardDetails from '../Components/Dashboard/DashboardDetails';
const Dashboard = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<>
			<Meta title='iLaw | Dashboard' />
			<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='container-fluid'>
				<div className='row gap-4 justify-content-center my-5'>
					<div className='col-12 col-md-3'>
						<DashBoardSideBar />
					</div>
					<div className='col-12 col-md-8'>
						<DashboardDetails />
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
