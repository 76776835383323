import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import logo from '../../assets/logo.3d3ebf04.png';
import styles from './Spinner.module.css';
const Spinner = () => {
	return (
		<div className={styles.spinnerContainer}>
			<div className=''>
				<img src={logo} alt='logo/img' className={`${styles.logoImg}`} />
				<ThreeDots height='80' width='200' radius='9' color='#f1c111' ariaLabel='three-dots-loading' visible={true} className='main-spineer text-center' />
			</div>
		</div>
	);
};

export default Spinner;
