import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	countries: [],
};
const countriesSlice = createSlice({
	name: 'countriesSlice',
  initialState,
  reducers: {
    addCountries: (state, action) => {
      state.countries = action.payload; 
    }
  }
});
export const {addCountries} = countriesSlice.actions
export default countriesSlice.reducer