import React from 'react';
import style from './CategoriesMenu.module.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { closeCategory } from '../../../../../../Redux/CategoryListState/CategoryListSlice';
import { MdArrowBackIosNew } from 'react-icons/md';
const CategoriesMenu = ({ setOpenCategoriesMenu }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const langState = useSelector((state) => state.langSlice.lang);
	const categories = useSelector((state) => state.CategorySlice.categoryItems);
	console.log(categories);
	return (
		<div>
			<div onClick={() => setOpenCategoriesMenu(false)} className={`${style.listContainer} my-2 py-1`}>
				<div className={`p-3  ${style.list}`}>
					<MdArrowBackIosNew /> {langState === 'en' ? 'Back' : 'الرجوع'}
				</div>
			</div>
			{categories.map((category, index) => (
				<div
					key={index}
					onClick={() => {
						navigate(`/${category.name_en}`);
						dispatch(closeCategory());
					}}
					className={`${style.listContainer} my-2 py-1`}
				>
					<Link to={`/${category.name_en}`} className={`p-3  ${style.list} ${style.link}`}>
						{langState === 'en' ? category.name_en : category.name_ar}
					</Link>
				</div>
			))}
		</div>
	);
};

export default CategoriesMenu;
