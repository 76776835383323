import React from 'react';
import style from './ShowPublisherAndWriterDetails.module.css';
import Title from '../../../Utilits/Title/Title';
import ShowBooks from '../../HomePage Component/GetBooks/ShowBooks/ShowBooks';
const ShowPublisherAndWriterDetails = ({ details, endPoint }) => {
	return (
		<>
			{/*publisher name and img*/}
			<div className='container'>
				<div className={`d-flex justify-content-between align-items-center ${style.pubDetailsContainer}`}>
					<img src={details.photo} alt='publisher/img' />
					<h4>{details.name}</h4>
				</div>
				<Title title='books' />
				{Object.keys(details).includes(endPoint) ? (
					<div>
						<ShowBooks items={details[endPoint]} />
					</div>
				) : (
					''
				)}
			</div>
		</>
	);
};

export default ShowPublisherAndWriterDetails;
