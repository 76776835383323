import React, { useRef, useEffect } from 'react';
import style from './Modal.module.css';
import BookInfo from '../BookDetailsPage/ShowBookDetails/BookInfo/BookInfo';
import { AiOutlineCloseCircle } from 'react-icons/ai';
const Modal = ({ showModal, item, setShowModal }) => {
	// const modalRef = useRef(null);
	// useEffect(() => {
	// 	const handleOutSideClick = (e) => {
	// 		if (modalRef.current && !modalRef.current.contains(e.target)) {
	// 			setShowModal(false);
	// 		}
	// 	};
	// 	if (showModal) {
	// 		document.addEventListener('mousedown', handleOutSideClick);
	// 	}
	// 	return () => {
	// 		document.removeEventListener('mousedown', handleOutSideClick);
	// 	};
	// }, [setShowModal , showModal]);
	return (
		<div  className={`p-3 ${style.mainContainer} ${showModal ? style.show : style.hide}`}>
			<div className={style.content}>
				<BookInfo details={item} isModal={true} />
				<div onClick={() => setShowModal(false)} className={style.icon}>
					<AiOutlineCloseCircle />
				</div>
			</div>
		</div>
	);
};

export default Modal;
