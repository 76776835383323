import { createSlice } from '@reduxjs/toolkit';

import Swal from 'sweetalert2';
const initialState = {
	openCart: false,
	cartItems: JSON.parse(window.localStorage.getItem('cart')) || [],
};
const cartSlice = createSlice({
	name: 'cartSlice',
	initialState,
	reducers: {
		// to open the cart
		openCart: (state) => {
			state.openCart = true;
		},
		// to close the cart
		closeCart: (state) => {
			state.openCart = false;
		},

		// add items to cart
		addToCart: (state, action) => {
			// check if item added before or not
			const index = state.cartItems.findIndex((item) => item.id === action.payload.id && item.text === action.payload.text);

			if (index >= 0) {
				state.cartItems[index].quantity++;
				Swal.fire({
					position: 'top-end',
					icon: 'success',
					title: `${action.payload.name} QTY increased`,
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				const temp = { ...action.payload, quantity: 1 };

				state.cartItems.push(temp);
				Swal.fire({
					position: 'top-end',
					icon: 'success',
					title: `${action.payload.name} Added to Cart`,
					showConfirmButton: false,
					timer: 1500,
				});
			}
			// update local storage
			localStorage.setItem('cart', JSON.stringify(state.cartItems));
		},
		// remove item from card
		removeFromCart: (state, action) => {
			const newItems = state.cartItems.filter((item) => item.id !== action.payload.id);
			// update the state
			state.cartItems = newItems;
			// show alert to user
			Swal.fire({
				position: 'top-end',
				icon: 'success',
				title: `${action.payload.name} Deleted from Cart`,
				showConfirmButton: false,
				timer: 1500,
			});
			// update local storage
			localStorage.setItem('cart', JSON.stringify(state.cartItems));
		},
		// clear cart
		clearCart: (state) => {
			state.cartItems = [];
			window.localStorage.setItem('cart', JSON.stringify(state.cartItems));
			Swal.fire({
				position: 'top-end',
				icon: 'success',
				title: `Cart Cleared Successfully`,
				showConfirmButton: false,
				timer: 1500,
			});
		},
		// increase quantity
		increaseQTY: (state, action) => {
			const index = state.cartItems.findIndex((item) => item.id === action.payload.id && item.text === action.payload.text);
			if (index >= 0) {
				state.cartItems[index].quantity += 1;
				Swal.fire({
					position: 'top-end',
					icon: 'success',
					title: `${action.payload.name} QTY increased`,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		},
		// decrease quantity
		decreaseQTY: (state, action) => {
			const index = state.cartItems.findIndex((item) => item.id === action.payload.id && item.text === action.payload.text);
			if (index >= 0) {
				if (state.cartItems[index].quantity > 1) {
					state.cartItems[index].quantity -= 1;
					Swal.fire({
						position: 'top-end',
						icon: 'success',
						title: `${action.payload.name} QTY Decreased`,
						showConfirmButton: false,
						timer: 1500,
					});
				}
			}
		},
	},
});
export const { openCart, closeCart, addToCart, removeFromCart, clearCart, increaseQTY, decreaseQTY } = cartSlice.actions;
export default cartSlice.reducer;
