import React from 'react';
import style from './ShowAboutDetails.module.css';
import aboutImg from '../../assets/about.jpg';
import { useSelector } from 'react-redux';
const ShowAboutDetails = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className={`mt-2 ${style.mainContainer}`}>
			<img src={aboutImg} alt='about/img' className={style.aboutImg} />
			<div className={style.aboutDetails}>
				<div className='p-3'>
					<h5 className='mb-5'>{langState === 'en' ? 'About us' : 'من نحن'}</h5>
					<p>
						{langState === 'en'
							? 'iLAW Fair is the first international legal book fair online. The idea of the site is to create a permanent international legal book fair that publishes, distributes and sells legal books and encyclopedias in the Middle East. It also works to provide all translated foreign legal titles and publications and support the specialized legal researcher with the latest developments. The jurisprudence, the judiciary and the innovative legal theories, which removes the burden of research and exploration for legal encyclopedias from lawyers, lawyers and postgraduate researchers, as the site contracts with all Arab and foreign publishing houses, senior professors and legal scholars in the Middle East to announce all their publications. Permanently, the iLAW Fair is one of the projects of ilaw for publishing and distribution, which is headquartered in the United Arab Emirates, which aims to provide the legal book in an easy way for the researcher.'
							: 'يعد موقع iLAW Fair أول معرض دولي للكتاب القانوني عبر الإنترنت ، تقوم فكرة الموقع علي انشاء معرض دولي دائم للكتاب القانوني يتولى نشر وتوزيع وبيع الكتب والموسوعات القانونية في منطقة الشرق الأوسط ، ايضا يعمل على توفير كافة العناوين والاصدارات القانونية الاجنبية المترجمة ودعم الباحث القانوني المتخصص بأحدث ما وقف عليه الفقه والقضاء والمستحدث من النظريات القانونية الامر الذي يزيل عن عاتق رجال القانون من المحامين وباحثي الدراسات العليا من الاكاديميين عبء البحث والتنقيب عن الموسوعات القانونية، حيث يتولى الموقع التعاقد مع كافة دور النشر العربية والاجنبية وكبار أساتذة وفقهاء القانون بالشرق الأوسط علي الاعلان عن كافة اصداراتهم بشكل دائم ، يعد iLAW Fair أحد مشروعات ilaw للنشر والتوزيع والتي يقع مقرها الرئيسي في دولة الامارات العربية المتحدة الهادفة لتوفير الكتاب القانوني بشكل يسير في متناول الباحث .'}
					</p>
					<p className='my-3'>
						{langState === 'en'
							? 'A general invitation to all legal publishing houses, judges, university professors and lawyers to place their books for free on the iLaw Fair website'
							: 'دعوة عامة لجميع دور النشر القانونية والقضاة واساتذة الجامعات والمحامون لوضع كتبهم مجاناً في موقع iLaw Fair'}
					</p>
					<p className='my-3'>
						{langState === 'en'
							? "Are you looking for an unlimited spread for your author, you can double your profits, by displaying your author on our site you can reach hundreds of thousands of readers all over the world in just a few moments, we provide you with this free service in addition to other services such as the ability to search in Arabic for your book via The title of the book, the author's name, or its own keys. Your book can also be accessed through the legal section or according to a specific topic"
							: 'هل تبحث عن انتشار غير محدود لمؤلفك ، بإمكانك مضاعفة أرباحك ،من خلال عرض مؤلفك في موقعنا بإمكانك الوصول إلى مئات الآلاف من القراء في جميع أنحاء العالم في لحظات معدودة ،نوفر لك هذه الخدمة المجانية إضافة إلى خدمات أخرى مثل إمكانية البحث باللغة العربية عن كتابك عبر عنوان الكتاب أو اسم المؤلف أو مفاتيح خاصة به، كما يمكن الوصول إلى كتابك عبر القسم القانوني او وفقا لموضوع بذاته.'}
					</p>
					<p className='my-3'>
						{langState === 'en'
							? 'Thats not all, your book will have a special page on the Internet via the iLAW fair website, and this page will contain detailed information about the book, and you can also provide us with a brief about the book to put it under the "Publishers Brief or Authors Brief" on the book page.'
							: 'هذا ليس كل شيء، سيكون لكتابك صفحة خاصة على الإنترنت عبر موقع iLAW fair، وهذه الصفحة ستتضمن معلومات مفصلة عن الكتاب، كما يمكنك تزويدنا بنبذة عن الكتاب لنضعها ضمن "نبذة الناشر" أو "نبذة المؤلف" على صفحة الكتاب.'}
					</p>
				</div>
			</div>
		</div>
	);
};

export default ShowAboutDetails;
