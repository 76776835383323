import React from 'react';
import AddressForm from './addressForm/AddressForm';
import OrderDetails from './orderDetails/OrderDetails';
import { useSelector } from 'react-redux';
const CheckoutDetails = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='container row gap-5 my-5'>
			<div className='text-center'>
				<p className='mb-5 text-uppercase text-center fw-bold fs-5'>checkout</p>
			</div>
			<div className='col-12 col-md-8 mb-4 mb-md-0'>
				<AddressForm />
			</div>
			<div className='col-12 col-md-3'>
				<OrderDetails />
			</div>
		</div>
	);
};

export default CheckoutDetails;
