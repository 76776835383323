import React, { useState, useEffect } from 'react';
import Meta from '../Components/Meta/Meta.jsx';
import { useSelector } from 'react-redux';
import Spinner from '../Components/Spinner/Spinner';
import Title from '../Utilits/Title/Title.jsx';
import ShowFaqDetails from '../Components/FaqPageComponent/ShowFaqDetails.jsx';
const Faq = () => {
	const mainApi = 'https://dashboard.ilawfair.com';
	const langState = useSelector((state) => state.langSlice.lang);
	const [loading, setLoading] = useState(true);
	const [faqDetails, setFaqDetails] = useState({});

	useEffect(() => {
		const FaqDetails = async () => {
			const res = await fetch(`${mainApi}/api/faqs`, {
				method: 'GET',
				headers: {
					lang: langState,
				},
			});
			const data = await res.json();
			setFaqDetails(data.data);
			setLoading(false);
		};
		FaqDetails();
	}, [langState]);

	return (
		<div>
			{loading ? (
				<Spinner />
			) : (
				<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
					<Title title={langState === 'en' ? 'Repated question' : 'الأسئلة المكررة'} />
					<Meta title={`iLaw | ${langState === 'en' ? 'Repated question' : 'الأسئلة المكررة'} `} />
					<div className='my-5'>
						<ShowFaqDetails details={faqDetails} />
					</div>
				</div>
			)}
		</div>
	);
};

export default Faq;
