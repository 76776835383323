// import hooks
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import style
import style from './SignUp.module.css';

// import reducers
import { closeForm, login, updateUserData } from '../../../../../Redux/authState/loginSlice';
// import icons
import { AiOutlineClose, AiFillFacebook } from 'react-icons/ai';
import { TfiPencilAlt } from 'react-icons/tfi';
import { FcGoogle } from 'react-icons/fc';
import Swal from 'sweetalert2';
import { BsPersonFill } from 'react-icons/bs';
const SignUp = ({ setNewAccount, isSideBar, setLogin }) => {
	const langState = useSelector((state) => state.langSlice.lang);
	const dispatch = useDispatch();
	// form input
	const [name, setName] = useState('');
	const [mail, setMail] = useState('');
	const [password, setPassword] = useState('');
	const [phone, setPhone] = useState('');
	const [confirmPassword, setConfiromPassword] = useState('');
	const [passwordMatch, setPasswordMatch] = useState(false);
	// error for validation
	const [nameErrorr, setNameError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [confirmPasswordError, setConfirmPasswordError] = useState('');
	const [mailError, setMailError] = useState('');

	const mainApi = 'https://dashboard.ilawfair.com';
	const lang = useSelector((state) => state.langSlice.lang);

	// handle values
	// - name value
	function handleNameChange(event) {
		setName(event.target.value);
		setNameError(validateName(event.target.value));
	}
	// - email value
	function handleEmailChange(event) {
		setMail(event.target.value);
		setMailError(validateEmail(event.target.value));
	}
	// - password
	function handlePasswordChange(event) {
		setPassword(event.target.value);
		setPasswordError(validatePassword(event.target.value));
	}

	// - confirm password
	function handleConfirmPasswordChange(event) {
		setConfiromPassword(event.target.value);
		setConfirmPasswordError(validateConfirmPassword(event.target.value));
	}

	// validation
	// - name validation
	function validateName(value) {
		if (value.trim() === '') {
			return 'Name is required';
		}

		return '';
	}
	// email validation
	function validateEmail(value) {
		if (value.trim() === '') {
			return 'Email is required';
		}

		if (!/\S+@\S+\.\S+/.test(value)) {
			return 'Email is invalid';
		}

		return '';
	}

	// password validation
	function validatePassword(value) {
		if (value.trim() === '') {
			return 'Password is required';
		}

		if (value.length < 8) {
			return 'Password must be at least 8 characters long';
		}

		return '';
	}

	// confirm password validation
	function validateConfirmPassword(value) {
		if (value.trim() === '') {
			return 'Confirm password is required';
		}

		if (value !== password) {
			return 'Passwords do not match';
		}
		if (value === password) {
			setPasswordMatch(true);
		}

		return '';
	}

	// add a custome style if the password matching

	const Regester = async (e) => {
		e.preventDefault();
		const nameError = validateName(name);
		const emailError = validateEmail(mail);
		const passwordError = validatePassword(password);
		const confirmPasswordError = validateConfirmPassword(confirmPassword);

		setNameError(nameError);
		setMailError(emailError);
		setPasswordError(passwordError);
		setConfirmPasswordError(confirmPasswordError);

		if (nameError || emailError || passwordError || confirmPasswordError) {
			return;
		} else {
			await fetch(`${mainApi}/api/user/register`, {
				method: 'POST',
				headers: {
					lang,
					'Content-Type': 'application/json',
					'Accepted-lang': lang,
				},
				body: JSON.stringify({
					name,
					password,
					email: mail,
					phone,
				}),
			})
				.then((data) => data.json())
				.then((res) => {
					if (res.status === 'success') {
						Swal.fire({
							position: 'top-end',
							icon: 'success',
							title: `Welcome ${res.data.name} Yor Account Created Successfully`,
							showConfirmButton: false,
							timer: 1500,
						});
						dispatch(login());
						dispatch(updateUserData(res.data));
						setName('');
						setPassword('');
						setPhone('');
						dispatch(closeForm());
					}
				});
		}
	};
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
			<div className='p-3'>
				<div className={`pb-4 d-flex justify-content-between align-items-center ${style.accountContainer} ${isSideBar ? 'd-block' : 'd-none'}`}>
					<div>
						<BsPersonFill size={30} />
						<span className='fw-bold'>{langState === 'en' ? 'Account' : 'الحساب'}</span>
					</div>
					<AiOutlineClose size={30} onClick={() => dispatch(closeForm(false))} className={style.closeContainer} />
				</div>
				<div className={`${style.newAccountContainer} py-4`}>
					<TfiPencilAlt size={30} />
					<span className='fw-bold ms-2'>{langState === 'en' ? 'New Account' : 'جساب جديد'}</span>
				</div>
				<form onSubmit={(e) => Regester(e)} className={`my-4`}>
					{/*name*/}
					<div>
						<label htmlFor='name' className='d-block fw-bold'>
							{langState === 'en' ? 'Name' : 'الإسم'}
						</label>
						<input onChange={handleNameChange} required type='text' id='name' className={`d-block ${style.inp} mb-3`} placeholder='Enter your Name' />
						{nameErrorr && <div className={`my-2 ${style.error}`}>{nameErrorr}</div>}
					</div>

					<label htmlFor='phone' className='d-block fw-bold'>
						{langState === 'en' ? 'Phone' : 'الموبايل'}
					</label>
					<input onChange={(e) => setPhone(e.target.value)} type='number' id='phone' className={`d-block ${style.inp} mb-3`} placeholder='Enter your Phone' />
					{/**email*/}
					<div>
						<label htmlFor='email' className='d-block fw-bold'>
							{langState === 'en' ? 'Email Address' : 'البريد الإلكتروني'}
						</label>
						<input required onChange={handleEmailChange} type='email' id='email' className={`d-block ${style.inp} mb-3`} placeholder='Enter your Email' />
						{mailError && <div className={`my-2 ${style.error}`}>{mailError}</div>}
					</div>
					{/*password*/}
					<div>
						<label htmlFor='password' className='d-block fw-bold'>
							{langState === 'en' ? 'Password' : 'كلمة السر'}
						</label>
						<input
							onChange={handlePasswordChange}
							required
							type='password'
							id='password'
							className={`d-block ${style.inp} mb-3 ${passwordMatch ? style.matchBorder : ''}`}
							placeholder='Enter your Password'
						/>
						{passwordError && <div className={`my-2 ${style.error}`}>{passwordError}</div>}
					</div>
					{/*confirm password*/}
					<div>
						<label htmlFor='confirm' className='d-block fw-bold'>
							{langState === 'en' ? 'Confirm Password' : 'تأكيد كلمة السر'}
						</label>
						<input
							onChange={handleConfirmPasswordChange}
							required
							type='password'
							id='confirm'
							className={`d-block ${style.inp} mb-3 ${passwordMatch ? style.matchBorder : ''}`}
							placeholder='Enter your Password'
						/>
						{confirmPasswordError && <div className={`my-2 ${style.error}`}>{confirmPasswordError}</div>}
					</div>

					<input value={langState === 'en' ? 'Create Account' : 'انشاء حساب'} type='submit' onClick={Regester} className={style.createBtn} />

					<span className={`d-block ${style.or} position-relative text-center py-5`}>{langState === 'en' ? 'Or' : 'أو'}</span>
					<div className='d-flex justify-content-center align-items-center gap-2 pb-5'>
						<FcGoogle size={40} className={`${style.icon}`} />
						<AiFillFacebook size={40} className={`${style.icon} ${style.face}`} />
					</div>
					<div className={`d-flex justify-content-center align-items-center gap-4 mt-3 ${isSideBar ? 'd-block' : 'd-none'}`}>
						<p className={style.havAccount}>{langState === 'en' ? 'Already Have an Account ?' : 'هل لديك حساب بالفعل ؟ '}</p>
						<p
							onClick={() => {
								setNewAccount(false);
								setLogin(true);
							}}
							className={`${style.login} fw-bold`}
						>
							{langState === 'en' ? 'Login' : 'تسجيل الدخول'}
						</p>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SignUp;
