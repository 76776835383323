import React, { useRef, useEffect, useState } from 'react';
import style from './CategorySideBar.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';

import { closeCategory } from '../../../../../Redux/CategoryListState/CategoryListSlice.js';
import CategorySettings from './Settings/CategorySettings';
import Social from './Social/Social';
import PagesMenu from './PagesMenu/PagesMenu';
import ShopMenu from './ShopMenu/ShopMenu';
import MainList from './MainList/MainList';
import LanguageMenu from './LanguageMenu/LanguageMenu';
import CategoriesMenu from './CategoriesMenu/CategoriesMenu';
const CategorySideBar = () => {
	// hooks
	const categorySideBar = useRef(null);
	const langState = useSelector((state) => state.langSlice.lang);
	const categoryState = useSelector((state) => state.CategorySlice.category);
	const dispatch = useDispatch();
	// useEffect(() => {
	// 	const handleClickOutSide = (e) => {
	// 		if (categorySideBar.current && !categorySideBar.current.contains(e.target)) {
	// 			dispatch(closeCategory(false));
	// 		}
	// 	};
	// 	if (categoryState) {
	// 		document.addEventListener('mousedown', handleClickOutSide);
	// 	}
	// 	return () => {
	// 		document.removeEventListener('mousedown', handleClickOutSide);
	// 	};
	// }, [dispatch, categoryState]);
	const [openPagseMenu, setOpenPagseMenu] = useState(false);
	const [openCategoriesMenu, setOpenCategoriesMenu] = useState(false);
	const [openLanguagesMenu, setOpenLanguagesMenu] = useState(false);
	const [openShopMenu, setOpenShopMenu] = useState(false);
	return (
		<div ref={categorySideBar} className={`${style.mainContainer} ${categoryState ? style.show : style.hide}`}>
			{/*title*/}
			<div className={`p-4 d-flex justify-content-between align-items-center ${style.titleContainer}`}>
				<h5 className=' text-uppercase'>{langState === 'en' ? 'Shop by category' : 'تصفح الأقسام'}</h5>
				<AiOutlineClose size={20} className={style.closeIcon} onClick={() => dispatch(closeCategory(false))} />
			</div>
			{/* category list*/}
			<div className='mb-5'>
				{!openPagseMenu && !openShopMenu && !openCategoriesMenu && !openLanguagesMenu && (
					<MainList setOpenPagseMenu={setOpenPagseMenu} setOpenCategoriesMenu={setOpenCategoriesMenu} setOpenLanguagesMenu={setOpenLanguagesMenu} />
				)}
				{openPagseMenu && !openShopMenu && !openCategoriesMenu && !openLanguagesMenu && <PagesMenu setOpenPagseMenu={setOpenPagseMenu} setOpenShopMenu={setOpenShopMenu} />}
				{openLanguagesMenu && !openShopMenu && !openPagseMenu && !openCategoriesMenu && <LanguageMenu setOpenLanguagesMenu={setOpenLanguagesMenu} />}
				{openCategoriesMenu && !openPagseMenu && !openShopMenu && !openLanguagesMenu && <CategoriesMenu setOpenCategoriesMenu={setOpenCategoriesMenu} />}
				{openShopMenu && !openPagseMenu && !openLanguagesMenu && !openCategoriesMenu && <ShopMenu setOpenShopMenu={setOpenShopMenu} setOpenPagseMenu={setOpenPagseMenu} />}
			</div>
			<CategorySettings />
			<Social />
		</div>
	);
};

export default CategorySideBar;
