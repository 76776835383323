import React, { useState } from 'react';
import style from './ShowBooks.module.css';
import { AiOutlineStar } from 'react-icons/ai';
import { MdOutlineFavorite } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addToWhishList } from '../../../../Redux/Whishlist/WhishlistSlice';
import Modal from '../../../Modal/Modal';
const ShowBooks = ({ items }) => {
	const [isFoundBook, setBookIsFound] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const { books, searchValue, selectValue } = useSelector((state) => state.BooksSlice);
	const filteredBooksBySearch = books.filter((book) => book.name.toLowerCase().includes(searchValue.toLowerCase()));
	const filterdBooksByCategories = books.filter((book) => book.section.name === searchValue);
	const currencyState = useSelector((state) => state.currencySlice.currency);
	const [bookModal, setBookModal] = useState({});
	console.log(books);
	const handleShowModal = (id) => {
		setShowModal(true);
		const book = books.filter((item) => item.id === id);
		setBookModal(...book);
	};
	return (
		<div className='container-fluid'>
			<div className='row'>
				{books.map((book, index) => (
					<div key={index} className={`${style.mainCard} col-12 col-sm-6 col-md-3 col-lg-2`}>
						{/*logo*/}
						<div className={style.imgContainer}>
							<Link to={`/book/${book.id}`}>
								<img src={book.photo} alt='book/img' className={style.logo} />
							</Link>
						</div>
						{/*text*/}
						<div className={style.textContainer}>
							{/* category*/}
							<p className={style.category}>{book.section.name}</p>
							{/* book name*/}
							<Link to={`/book/${book.id}`} className={style.name}>
								<p>{book.name}</p>
							</Link>
						</div>
						{/*price container*/}
						<div>
							{book.pdfCopy.status === 1 && book.pdfCopy.price.currrent_price !== 0 ? (
								<div>
									<h5 className={`mb-2 ${style.pdf}`}>{book.pdfCopy.text}</h5>
									<p className={`d-flex align-items-center mt-4  gap-2 ${style.price}`}>
										<del>
											{book.pdfCopy.price.original} {currencyState}
										</del>
										<p className='my-1'>
											{book.pdfCopy.price.currrent_price} {currencyState}
										</p>
									</p>
								</div>
							) : (
								''
							)}
						</div>
						{/* reveiw container*/}
						<div className={`d-flex align-items-center gap-3`}>
							{/*icons*/}
							<div className={`d-flex  align-items-center`}>
								<AiOutlineStar size={15} />
								<AiOutlineStar size={15} />
								<AiOutlineStar size={15} />
								<AiOutlineStar size={15} />
							</div>
							<span>0.00</span>
						</div>

						{/*overflow*/}

						<p onClick={() => handleShowModal(book.id)} className={style.addToCart}>
							add to cart
						</p>
						{showModal && <Modal showModal={showModal} setShowModal = {setShowModal} item={bookModal} />}
					</div>
				))}
			</div>
		</div>
	);
};

export default ShowBooks;

/*
  
*/
