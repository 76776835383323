import React from 'react';
import AccountDetailsForm from '../Components/Dashboard/AccountDetails/AccountDetailsForm';
import DashBoardSideBar from '../Components/Dashboard/SideBar/DashBoardSideBar';
import Meta from '../Components/Meta/Meta.jsx';
import { useSelector } from 'react-redux';
const AccountDetails = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<>
			<Meta title='iLaw | Account Details' />
			<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='container-fluid'>
				<div className='row gap-4 justify-content-center my-5'>
					<div className='col-12 col-md-3'>
						<DashBoardSideBar />
					</div>
					<div className='col-12 col-md-8'>
						<AccountDetailsForm />
					</div>
				</div>
			</div>
		</>
	);
};

export default AccountDetails;
