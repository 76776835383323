import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserData } from '../../../Redux/authState/loginSlice';
import { addCountries } from '../../../Redux/Countries/Countries.js';
import style from './AccountDetailsForm.module.css';
import Spinner from '../../Spinner/Spinner';
import Swal from 'sweetalert2';
const AccountDetailsForm = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	const userData = JSON.parse(window.localStorage.getItem('user'));
	const mainApi = 'https://dashboard.ilawfair.com';
	const [countries, setCountries] = useState([]);
	const [loading, setLoading] = useState(true);
	const [name, setName] = useState(userData.name);
	const [userName, setUserName] = useState(userData.userName);
	const [mail, setMail] = useState(userData.email);
	const [userCountry, setUserCountry] = useState(userData.country.id);
	const [address, setAddress] = useState(userData.address);
	const [phone, setPhone] = useState(userData.phone);
	const [defaultLang, setDefaultLang] = useState(userData.language);
	const dispatch = useDispatch();

	// fetch countries
	useEffect(() => {
		const fetchCountries = async () => {
			const res = await fetch(`${mainApi}/api/countries`, {
				method: 'GET',
				headers: {
					lang: langState,
				},
			});
			const data = await res.json();
			setCountries(data.data);
			dispatch(addCountries(data.data));
			setLoading(false);
		};
		fetchCountries();
	}, [langState]);
	// handle change user data
	const handleSubmit = async (e) => {
		e.preventDefault();
		await fetch(`${mainApi}/api/user/update-my-profile`, {
			method: 'POST',
			headers: {
				lang: langState,
				user: userData.id,
				'Content-Type': 'application/json',
				'Accepted-lang': langState,
			},
			body: JSON.stringify({
				name,
				userName,
				country: userCountry,
				phone,
				email: mail,
				address,
			}),
		})
			.then((data) => data.json())
			.then((res) => {
				if (res.status === 'success') {
					Swal.fire({
						position: 'top-end',
						icon: 'success',
						title: `Welecom ${res.data.name} You Data Changed Successfully`,
						showConfirmButton: false,
						timer: 1500,
					});
					dispatch(updateUserData(res.data));
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res.message,
					});
				}
			});
	};
	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<div dir={langState === 'en' ? 'ltr' : 'rtl'} className={`ps-3 pb-3 ${style.mainContainer}`}>
					<h4 className='text-center mb-5'>{langState === 'en' ? 'Account Details' : 'تفاصيل الحساب'}</h4>
					<h6 className='mb-2 text-center text-md-start'>{langState === 'en' ? 'Edit Account' : 'تعديل الحساب'}</h6>
					<form onSubmit={(e) => handleSubmit(e)}>
						<div className={`text-center mb-5 ${style.imgContainer}`}>
							<img src={userData.photo} className={`img-fluid ${style.userImg}`} alt='' />
						</div>
						{/*name and userName container*/}
						<div className='d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center'>
							<div className='w-100 w-md-50'>
								<label className='fw-bold d-block' htmlFor='name'>
									Name
								</label>
								<input className={`w-100 ${style.inp}`} type='text' value={name} onChange={(e) => setName(e.target.value)} />
							</div>
							<div className='w-100 w-md-50'>
								<label className='fw-bold d-block' htmlFor='user'>
									UserName
								</label>
								<input className={`w-100 ${style.inp}`} type='text' value={userName} onChange={(e) => setUserName(e.target.value)} />
							</div>
						</div>
						{/*email address*/}
						<div className='w-100 my-5'>
							<label htmlFor='email' className='fw-bold d-block'>
								Email Address
							</label>
							<input type='email' value={mail} className={`w-100 ${style.inp}`} onChange={(e) => setMail(e.target.value)} />
						</div>
						{/*countries*/}
						<div className='w-100'>
							<label htmlFor='country' className='d-block fw-bold'>
								Country
							</label>
							<select value={userCountry} className={`w-100 ${style.inp}`} id='country' onChange={(e) => setUserCountry(e.target.value)}>
								<option value=''>Select your Country</option>
								{countries.map((country, index) => (
									<option value={country.name} key={index}>
										{country.name}
									</option>
								))}
							</select>
						</div>
						{/*address*/}
						<div className='w-100 my-5'>
							<label htmlFor='address' className='d-block fw-bold'>
								Addressess
							</label>
							<input type='text' className={`w-100 ${style.inp}`} value={address} onChange={(e) => setAddress(e.target.value)} />
						</div>
						{/*phone*/}
						<div className='w-100 my-5'>
							<label htmlFor='phone' className='d-block fw-bold'>
								Phone
							</label>
							<input type='number' className={`w-100 ${style.inp}`} value={phone} onChange={(e) => setPhone(e.target.value)} />
						</div>
						{/*default lang*/}
						<div className='w-100 my-5'>
							<label htmlFor='lang' className='d-block fw-bold'>
								Default Language
							</label>
							<select className={`w-100 ${style.inp}`} id='lang' value={defaultLang} onChange={(e) => setDefaultLang(e.target.value)}>
								<option value='ar'>Arabic</option>
								<option value='en'>English</option>
								<option value='fr'>Frensh</option>
							</select>
						</div>
						<div className='w-100 my-5 d-flex justify-content-center'>
							<input type='submit' value='Save changes' className={style.save} />
						</div>
					</form>
					{/**change password form */}
					<div className={`py-5 px-3 ${style.changePassword}`}>
						<h6 className='mb-3 fw-bold text-center'>Change Password</h6>
						<form>
							{/*current password*/}
							<div className='mb-4'>
								<label htmlFor='current' className='d-block fw-bold'>
									current password
								</label>
								<input type='password' id='current' className={`mb-3 ${style.inp} w-100`} />
							</div>
							{/*new password*/}
							<div className='mb-4'>
								<label htmlFor='new' className='d-block fw-bold'>
									New password
								</label>
								<input type='password' id='new' className={`mb-3 ${style.inp} w-100`} />
							</div>
							{/*confirm*/}
							<div className='mb-4'>
								<label htmlFor='confirm' className='d-block fw-bold'>
									confirm password
								</label>
								<input type='password' id='confim' className={`mb-3 ${style.inp} w-100`} />
							</div>
							<div className='d-flex justify-content-center'>
								<input type='submit' value='Save changes' className={style.save} />
							</div>
						</form>
					</div>
				</div>
			)}
		</>
	);
};

export default AccountDetailsForm;
