import { createSlice } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';

const initialState = {
	whishListItems: JSON.parse(localStorage.getItem('whishlist')) || [],
};
const whishlistSlice = createSlice({
	name: 'whishlistSlice',
	initialState,
	reducers: {
		// add item to whish list
		addToWhishList: (state, action) => {
			const index = state.whishListItems.findIndex((item) => item.id === action.payload.id);
			if (index >= 0) {
				Swal.fire({
					position: 'top-end',
					icon: 'success',
					title: `${action.payload.name} Already in Your Whishlist`,
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				state.whishListItems.push(action.payload);
				Swal.fire({
					position: 'top-end',
					icon: 'success',
					title: `${action.payload.name} Added to whishlist`,
					showConfirmButton: false,
					timer: 1500,
				});
				// add whish list to local storage
				localStorage.setItem('whishlist', JSON.stringify(state.whishListItems));
			}
		},
		// remove item from whishlist
		removeFromWhishList: (state, action) => {
			const newWhisListItems = state.whishListItems.filter((item) => item.id !== action.payload.id);
			state.whishListItems = newWhisListItems;
			Swal.fire({
				position: 'top-end',
				icon: 'success',
				title: `${action.payload.name}Removed From Your Whishlist`,
				showConfirmButton: false,
				timer: 1500,
			});
			// update local storage
			localStorage.setItem('whishlist', JSON.stringify(state.whishListItems));
		},
		clearWhishList: (state) => {
			state.whishListItems = [];
			// update local storage :
			localStorage.setItem('whishlist', JSON.stringify(state.whishListItems));
		},
	},
});
export const { addToWhishList, removeFromWhishList, clearWhishList } = whishlistSlice.actions;
export default whishlistSlice.reducer;
