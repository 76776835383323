import React from 'react';

import { useParams } from 'react-router-dom';

import GetPublishersAndWritersDetails from '../Components/PublishersAndWritersDetails/GetPublishersAndWritersDetails';
import { useSelector } from 'react-redux';
const PublisherDetails = () => {
	const params = useParams();
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<>
			<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='my-5'>
				<GetPublishersAndWritersDetails apiPath='publishers' id={params.publisherId} endPoint='publisherBooks' />
			</div>
		</>
	);
};

export default PublisherDetails;
