import React, { useState } from 'react';
import style from './Login.module.css';
import { BsPersonFill } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { AiFillFacebook, AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { closeForm, login, updateUserData } from '../../../../../Redux/authState/loginSlice';
import Swal from 'sweetalert2';
const Login = ({ setNewAccount, isSideBar, setLogin, setResetPassword }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const langState = useSelector((state) => state.langSlice.lang);
	const dispatch = useDispatch();
	const mainApi = 'https://dashboard.ilawfair.com';
	const handleSubmit = async (e) => {
		e.preventDefault();
		await fetch(`${mainApi}/api/user/login`, {
			method: 'POST',
			headers: {
				lang: langState,
				'Content-Type': 'application/json',
				'Accepted-lang': langState,
			},
			body: JSON.stringify({
				email,
				password,
			}),
		})
			.then((data) => data.json())
			.then((res) => {
				console.log(res);
				if (res.status === 'faild') {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res.message,
					});
				} else if (res.status === 'success') {
					dispatch(login());
					dispatch(updateUserData(res.data));
					Swal.fire({
						position: 'top-end',
						icon: 'success',
						title: `Welecom ${res.data.name} You login successfully`,
						showConfirmButton: false,
						timer: 1500,
					});
					setEmail('');
					setPassword('');
					dispatch(closeForm());
				}
			});
	};
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className={style.mainContainer}>
			<div className={`d-flex justify-content-between align-items-center ${style.accountContainer} pb-3`}>
				{/*account icons*/}
				<div className={`${style.accountIconContainer}`}>
					<BsPersonFill size={30} className='me-2' />
					{langState === 'en' && isSideBar && <span className='fw-bold'>Account</span>}
					{langState !== 'en' && isSideBar && <span className='fw-bold'>الحساب</span>}
					{langState === 'en' && !isSideBar && <span className='fw-bold'>Login</span>}
					{langState !== 'en' && !isSideBar && <span className='fw-bold'>تسجيل الدخول</span>}
				</div>
				{/*closeIcons*/}
				<div className={`${style.closeIconContainer} ${isSideBar ? 'd-block' : 'd-none'}`}>
					<AiOutlineClose size={30} onClick={() => dispatch(closeForm(false))} className={style.closeIcon} />
				</div>
			</div>
			<form onSubmit={(e) => handleSubmit(e)} className='py-4 px-2'>
				<label htmlFor='email' className='d-block fw-bold'>
					{langState === 'en' ? '	Email Address' : 'البريد الإلكتروني'}
				</label>
				<input onChange={(e) => setEmail(e.target.value)} type='text' id='email' className={`d-block ${style.inp} mb-3`} placeholder='Enter your mail' />
				<label htmlFor='password' className='d-block fw-bold'>
					{langState === 'en' ? 'Password' : 'كلمة السر'}
				</label>
				<input onChange={(e) => setPassword(e.target.value)} type='password' id='password' placeholder='Enter your Password' className={`d-block ${style.inp} mb-3`} />
				<input type='submit' value={langState === 'en' ? 'Log in' : 'تسجيل الدخول'} className={style.loginBtn} />
			</form>
			<div className='py-4 px-2 d-flex justify-content-between align-items-center'>
				<div>
					<label className={`me-2 ${style.remember} fw-bold`} htmlFor='remember'>
						{langState === 'en' ? 'Remeber Me' : 'تذكرني'}
					</label>
					<input type='checkbox' id='remember' />
				</div>
				<Link
					onClick={() => {
						setNewAccount(false);
						setLogin(false);
						setResetPassword(true);
					}}
					className={`f-bold ${style.forget}`}
				>
					{langState === 'en' ? 'Foreget Passwprd ?' : 'هل نسيت كلمة المرور ؟ '}
				</Link>
			</div>

			<span className={`d-block ${style.or} position-relative text-center py-5`}>{langState === 'en' ? 'Or' : 'أو'}</span>
			<div className='d-flex justify-content-center align-items-center gap-2 pb-5'>
				<FcGoogle size={40} className={`${style.icon}`} />
				<AiFillFacebook size={40} className={`${style.icon} ${style.face}`} />
			</div>
			<button
				onClick={() => {
					setNewAccount(true);
					setLogin(false);
					setResetPassword(false);
				}}
				className={`${style.btn} ${isSideBar ? 'd-block' : 'd-none'}`}
			>
				{langState === 'en' ? 'New Account' : 'حساب جديد'}
			</button>
			<button className={`${style.btn} ${isSideBar ? 'd-block' : 'd-none'}`}>
				<a className={style.outLink} href='https://dashboard.ilawfair.com/publisher/auth/login' target='_blank'>
					{langState === 'en' ? 'Log in As A Vendor' : 'حساب جديد'}
				</a>
			</button>
			<button className={`${style.btn} ${isSideBar ? 'd-block' : 'd-none'}`}>
				<a className={style.outLink} href='https://dashboard.ilawfair.com/publisher/auth/register' target='_blank'>
					{langState === 'en' ? 'New Publisher Account' : 'حساب ناشر جديد'}
				</a>
			</button>
		</div>
	);
};

export default Login;
