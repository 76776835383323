import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { GiSmartphone } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import style from './ContactSection.module.css';
const ContactSection = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
			{langState === 'en' ? <h6 className='text-center '>contact us</h6> : <h6 className='text-center '>تواصل معنا</h6>}
			<ul className={`d-flex align-items-center justify-content-center gap-2 gap-md-4  ${style.contactContainer}`}>
				<li>
					<a href='mailto:info@ilawfair.com' target='_blank' className={`${style.navIcons} ${style.info}`}>
						<AiOutlineMail /> info@ilawfair.com
					</a>
				</li>
				<li>
					<a href='tel:971564093620' target='_blank' className={`${style.navIcons} ${style.info}`}>
						<GiSmartphone /> 971564093620
					</a>
				</li>
			</ul>
		</div>
	);
};

export default ContactSection;
