import React from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { AiFillInstagram, AiFillYoutube, AiOutlineTwitter } from 'react-icons/ai';
import style from './Social.module.css'
const Social = () => {
	return (
		<div className='d-flex gap-4 align-items-center p-4 justify-content-center'>
			<FaFacebookF size={20} className={style.facebook} />
			<AiFillInstagram size={20} className={style.insta} />
			<AiFillYoutube size={20} className={style.youtube} />
			<AiOutlineTwitter size={20} className={style.twitter} />
		</div>
	);
};

export default Social;
