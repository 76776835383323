import React, { useState } from 'react';
import { AiOutlineStar } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import style from './BookInfo.module.css';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../../../../Redux/CartState/CartSlice.js';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import { BsShare } from 'react-icons/bs';
import { addToWhishList } from '../../../../Redux/Whishlist/WhishlistSlice';
import { openForm } from '../../../../Redux/authState/loginSlice.js';
import Tabs from '../Tabs/Tabs';
import TabContent from '../Tabs/TabContent';
const BookInfo = ({ details, isModal }) => {
	console.log(details);
	const langState = useSelector((state) => state.langSlice.lang);
	const currencyState = useSelector((state) => state.currencySlice.currency);
	const whishListItems = useSelector((state) => state.WhishlistSlice.whishListItems);
	const [selectedImg, setSelectedImg] = useState(0);
	const [selectHardCopyBookIndex, setSelectHardCopyBookIndex] = useState(null);
	const [book, setBook] = useState({});
	const [selectPdf, setSelectPdf] = useState(false);
	const [selectHardCopy, setSelectHardCopy] = useState(false);
	const login = useSelector((state) => state.loginSlice.isLogin);
	const dispatch = useDispatch();
	const handleHardCopyClick = (index, myArray) => {
		setSelectHardCopyBookIndex(index);
		setSelectHardCopy(true);
		setSelectPdf(false);
		const item = {
			id: details.id,
			text: details.hardCopy.text,
			img: details.photo,
			name: details.name,
			price: myArray[index].price,
			branchId: myArray[index].user_address_id,
		};
		setBook(item);
	};
	const handlePdfCopyClick = () => {
		setSelectPdf(true);
		setSelectHardCopyBookIndex(null);
		const item = {
			id: details.id,
			text: details.pdfCopy.text,
			img: details.photo,
			price: details.pdfCopy.price.currrent_price,
			branchId: null,
			name: details.name,
		};
		setBook(item);
	};
	const handleAddToCart = () => {
		if (!selectHardCopy && !selectPdf) {
			Swal.fire({
				position: 'top-end',
				icon: 'error',
				title: 'Oops...',
				text: 'You Must Chose the book first',
			});
		} else {
			dispatch(addToCart(book));
		}
	};

	// handle add to whish list and change color if the item already in whish list
	const handleAddToWishList = (val) => {
		if (login) {
			dispatch(addToWhishList(val));
		} else {
			dispatch(openForm(true));
		}
	};

	const index = whishListItems.findIndex((item) => item.id === details.id);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
			{isModal ? (
				<div>
					<div className='d-flex flex-column flex-md-row justify-content-between align-items-center mb-4'>
						<img src={details.photo} className={style.modalImg} alt='' />
						<h4 className='fw-bold'>{details.name}</h4>
					</div>
					<div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
						<Link className={style.links} to={`/writer/${details.writer.id}`}>
							{details.writer.name}
						</Link>
						<Link className={style.links} to={`/writer/${details.publisher.id}`}>
							{details.publisher.name}
						</Link>
					</div>
					<div className=' row gap-2 mt-3 text-center  justify-content-center'>
						{/*book price list if the book is hardcopy version*/}
						{details.hardCopy.status === 1
							? details.hardCopy.price_list.map((item, index) => {
									return (
										<div
											onClick={() => handleHardCopyClick(index, details.hardCopy.price_list)}
											className={`col-3 ${style.card} ${selectHardCopyBookIndex === index ? style.selected : ''} `}
											key={index}
										>
											<p className={style.price}>
												{item.price} {currencyState}
											</p>
											<p className={style.address}>{item.title_branch}</p>
										</div>
									);
							  })
							: ''}
						{/*book price if the book is pdfcopy version*/}
						{details.pdfCopy.status === 1 && details.pdfCopy.price.currrent_price !== 0 ? (
							<div className={`col-3 ${style.card} ${selectPdf ? style.selected : ''}`} onClick={handlePdfCopyClick}>
								<p className={style.price}>
									{details.pdfCopy.price.currrent_price} {currencyState}
								</p>
								<p className={style.address}>{details.pdfCopy.text}</p>
							</div>
						) : (
							''
						)}
					</div>
					<div className={`d-flex  align-items-center justify-content-center gap-3 mt-3 ${style.cartContainer}`}>
						<button className={style.cartBtn} onClick={handleAddToCart}>
							Add To Cart
						</button>
					</div>
				</div>
			) : (
				<>
					<div className='row  gap-2'>
						{/*img container*/}
						<div className={`p-2 ${style.imgsContainer} col-12 col-md-4 mb-5`}>
							<img src={details.slider[selectedImg]} className={`p-3 mb-5 ${style.mainImg}`} alt='' />

							<div className={` d-flex justify-content-center mb-3 gap-1`}>
								{details.slider.map((item, index) => (
									<img className={style.sliderImg} src={item} key={index} onClick={() => setSelectedImg(index)} />
								))}
							</div>
						</div>
						<div className='col-12 col-md-7 mb-3'>
							<h3 className={`mb-2 mb-md-4 text-center text-md-start `}>{details.name}</h3>
							<div className={`row align-items-center`}>
								<div className={` col-12 text-center text-md-start mb-3 mb-md-0 col-md-4`}>
									<AiOutlineStar size={15} />
									<AiOutlineStar size={15} />
									<AiOutlineStar size={15} />
									<AiOutlineStar size={15} />
									<AiOutlineStar size={15} />
									<span>(0.00)</span>
								</div>
								{/*link refer to author page details*/}
								<Link to={`/writer/${details.writer.id}`} className={`${style.links} fw-bold col-12 col-md-3 mb-3 text-center text-md-start mb-md-0`}>
									by (author)
								</Link>
								{/*link refer to publisher page detauls*/}
								<Link to={`/publisher/${details.publisher.id}`} className={`col-12 col-md-4 mb-3 text-center text-md-start mb-md-0 ${style.links} fw-bold`}>
									seller (publisher)
								</Link>
							</div>
							<div className=' gap-2 row text-center  justify-content-center mt-3 mt-md-5'>
								{/*book price list if the book is hardcopy version*/}
								{details.hardCopy.status === 1
									? details.hardCopy.price_list.map((item, index) => {
											return (
												<div
													onClick={() => handleHardCopyClick(index, details.hardCopy.price_list)}
													className={` col-6 col-md-3 col-lg-2 ${style.card} ${selectHardCopyBookIndex === index ? style.selected : ''} `}
													key={index}
												>
													<p className={style.price}>
														{item.price} {currencyState}
													</p>
													<p className={style.address}>{item.title_branch}</p>
												</div>
											);
									  })
									: ''}
								{/*book price if the book is pdfcopy version*/}
								{details.pdfCopy.status === 1 && details.pdfCopy.price.currrent_price !== 0 ? (
									<div className={`col-6 col-md-3 col-lg-2 ${style.card} ${selectPdf ? style.selected : ''}`} onClick={handlePdfCopyClick}>
										<p className={style.price}>
											{details.pdfCopy.price.currrent_price} {currencyState}
										</p>
										<p className={style.address}>{details.pdfCopy.text}</p>
									</div>
								) : (
									''
								)}
							</div>
							<div className={`d-flex  align-items-center justify-content-center gap-3 my-5 ${style.cartContainer}`}>
								<button className={style.cartBtn} onClick={handleAddToCart}>
									Add To Cart
								</button>
							</div>
							<div className={`my-2 ${style.share}`}>
								<div className='p-3 py-5'>
									<div className='d-flex justify-content-center align-items-center gap-5'>
										{index >= 0 ? (
											''
										) : (
											<div onClick={() => handleAddToWishList(details)} className={style.iconContainer}>
												<MdOutlineFavoriteBorder className={style.icon} />
												<span>Add to whish list</span>
											</div>
										)}

										<div className={style.iconContainer}>
											<BsShare className={style.icon} />
											<span>Share</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* tabs*/}

					<Tabs>
						<TabContent title={langState === 'en' ? 'Description' : 'الوصف'}>
							<p>
								<p dangerouslySetInnerHTML={{ __html: details.full_des }}></p>
							</p>
						</TabContent>
						<TabContent title={langState === 'en' ? 'index' : 'الفهرس'}>
							<p>
								<p dangerouslySetInnerHTML={{ __html: details.index }}></p>
							</p>
						</TabContent>
						<TabContent title={langState === 'en' ? 'About Author' : 'عن الكاتب'}>
							<div className='d-flex justify-content-between align-items-center'>
								<div className='d-flex flex-column flex-md-row align-items-center gap-5'>
									<Link to={`/writer/${details.writer.id}`}>
										<img src={details.writer.photo} alt='' className={style.pubImg} />
									</Link>
									<Link className={`fw-bold fs-5 ${style.links}`} to={`/writer/${details.writer.id}`}>
										<p>{details.writer.name}</p>
									</Link>
								</div>
								<Link to=''>
									<p></p>
								</Link>
							</div>
						</TabContent>
						<TabContent title={langState === 'en' ? 'About Publisher' : 'عن الناشر'}>
							<div className='d-flex justify-content-between align-items-center text-center'>
								<div className='d-flex flex-column flex-md-row align-items-center gap-5'>
									<Link to={`/publisher/${details.publisher.id}`}>
										<img src={details.publisher.photo} alt='' className={style.pubImg} />
									</Link>
									<Link className={`fw-bold fs-5 ${style.links}`} to={`/writer/${details.publisher.id}`}>
										<p>{details.publisher.name}</p>
									</Link>
								</div>
								<Link className={style.link} to=''></Link>
							</div>
						</TabContent>
						<TabContent title={langState === 'en' ? 'Reviews' : 'المراجعات'}>
							<div className={`container ${style.reviewContainer}`}>
								<div className='d-flex justify-content-between align-items-center'>
									{/**leftside */}
									<div className={style.leftSideContainer}>
										<p className='fw-bold mb-4'>customers reviews</p>
										<div className='mb-5 d-flex align-items-center gap-2'>
											<p className={style.totalReveiw}>0.00</p>
											<div>
												<p>0 Rveiwes</p>
												<AiOutlineStar size={10} />
												<AiOutlineStar size={10} />
												<AiOutlineStar size={10} />
												<AiOutlineStar size={10} />
												<AiOutlineStar size={10} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</TabContent>
					</Tabs>
				</>
			)}
		</div>
	);
};

export default BookInfo;
