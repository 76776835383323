import React, { useState, useEffect } from 'react';
import Spinner from '../../Spinner/Spinner';
import Title from '../../../Utilits/Title/Title';
import ShowWriterAndPub from './ShowWriter&Pup/ShowWriterAndPub';
import { useSelector } from 'react-redux';
const GetWriteAndPub = ({ title, apiPath, bg, detailsPath, isWriter, arabicTitle }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const langState = useSelector((state) => state.langSlice.lang);
	const mainApi = 'https://dashboard.ilawfair.com';

	// fetching books
	useEffect(() => {
		const getData = async () => {
			const res = await fetch(`${mainApi}/api${apiPath}`, {
				method: 'GET',
				headers: {
					lang: langState,
				},
			});
			const data = await res.json();
			setData(data.data);
			setLoading(false);
		};
		getData();
	}, [langState]);
	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<div className='my-5'>
					<Title title={title} arabicTitle={arabicTitle} />
					<ShowWriterAndPub items={data} bg={bg} detailsPath={detailsPath} isWriter={isWriter} />
				</div>
			)}
		</>
	);
};

export default GetWriteAndPub;
