import React from 'react';
import style from './Dashboard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../Redux/authState/loginSlice';
import { MdOutlineBorderColor, MdOutlineFavoriteBorder, MdPayment } from 'react-icons/md';
import { FiBookOpen } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { CiUser } from 'react-icons/ci';
import { Link } from 'react-router-dom';

const DashboardDetails = () => {
	const dispatch = useDispatch();
	const langState = useSelector((state) => state.langSlice.lang);
	const userData = JSON.parse(window.localStorage.getItem('user'));
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
			<h6 className='mb-5 text-center'>Dashboard</h6>
			<div className='mb-5  text-center text-md-start'>
				<p className='fw-bold'>Hello {userData.name} </p>( not {userData.name} ?{' '}
				<span className={style.logout} onClick={() => dispatch(logOut())}>
					Logout
				</span>
				)
			</div>

			<p className={`${style.desc} text-center text-md-start`}>
				From your account dashboard you can view your <span className={style.details}>recent orders</span>, manage your <span className={style.details}>shipping and billing addresses</span>{' '}
				and edit your <span className={style.details}>password and account details.</span>
			</p>
			<div className='row my-5'>
				<div className={`col-12 col-md-4 ${style.mainContainer}`}>
					<Link to='/orders' className={`mb-3 ${style.orderContainer} d-flex justify-content-center align-items-center`}>
						<MdOutlineBorderColor size={50} />
					</Link>
					<p className='text-center'>Orders</p>
				</div>
				<div className={`col-12 col-md-4 ${style.mainContainer}`}>
					<Link to='/books' className={`mb-3 ${style.iconContainer} d-flex justify-content-center align-items-center`}>
						<FiBookOpen size={50} />
					</Link>
					<p className='text-center'>Books</p>
				</div>
				<div className={`col-12 col-md-4 ${style.mainContainer}`}>
					<Link to='/books' className={`mb-3 ${style.iconContainer} d-flex justify-content-center align-items-center`}>
						<GoLocation size={50} />
					</Link>
					<p className='text-center'>Addresses</p>
				</div>
				<div className={`col-12 col-md-4 ${style.mainContainer}`}>
					<Link to='/accountdetails' className={`mb-3 ${style.iconContainer} d-flex justify-content-center align-items-center`}>
						<CiUser size={50} />
					</Link>
					<p className='text-center'>AccountDetails</p>
				</div>
				<div className={`col-12 col-md-4 ${style.mainContainer}`}>
					<Link to='/wishlist' className={`mb-3 ${style.iconContainer} d-flex justify-content-center align-items-center`}>
						<MdOutlineFavoriteBorder size={50} />
					</Link>
					<p className='text-center'>Wishlist</p>
				</div>
				<div className={`col-12 col-md-4 ${style.mainContainer}`}>
					<Link to='/paymentmethods' className={`mb-3 ${style.iconContainer} d-flex justify-content-center align-items-center`}>
						<MdPayment size={50} />
					</Link>
					<p className='text-center'>Payment Methods</p>
				</div>
			</div>
		</div>
	);
};

export default DashboardDetails;
