import React from 'react';
import CheckoutDetails from '../Components/CheckoutPageComp/CheckoutDetails';
import { useSelector } from 'react-redux';
const Checkout = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
			<CheckoutDetails />
		</div>
	);
};

export default Checkout;
