import React from 'react';

import SignUp from '../../SharedLayout/AuthSideBar/Forms/SignUp/SignUp';
import Login from '../../SharedLayout/AuthSideBar/Forms/Login/Login';
import { useSelector } from 'react-redux';
const AuthForms = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='container-fluid py-4'>
			<div className='row gap-3'>
				<div className='col-12 col-md-5 gap-5'>
					<Login isSideBar={false} />
				</div>
				<div className='col-12 col-md-5'>
					<SignUp isSideBar={false} />
				</div>
			</div>
		</div>
	);
};

export default AuthForms;
