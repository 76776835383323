import React from 'react';
import DashBoardSideBar from '../Components/Dashboard/SideBar/DashBoardSideBar';
import Meta from '../Components/Meta/Meta.jsx';
import { useSelector } from 'react-redux';
import WishlistDetails from '../Components/Dashboard/Wishlist/WishlistDetails';
const Whishlist = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div>
			<>
				<Meta title='iLaw | Wishlist' />
				<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='container-fluid'>
					<div className='row   gap-4 my-5'>
						<div className='col-12  col-md-3'>
							<DashBoardSideBar />
						</div>
						<div className='col-12 col-md-8 '>
							<WishlistDetails />
						</div>
					</div>
				</div>
			</>
		</div>
	);
};

export default Whishlist;
