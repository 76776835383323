import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spinner from '../Components/Spinner/Spinner';
import ShowBookDetails from '../Components/BookDetailsPage/ShowBookDetails/ShowBookDetails';
import Meta from '../Components/Meta/Meta.jsx';
const BookDetails = () => {
	const mainApi = 'https://dashboard.ilawfair.com';
	const params = useParams();
	const langState = useSelector((state) => state.langSlice.lang);
	const [bookDetails, setBookDetails] = useState({});
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		window.scrollTo(0, 0);
		const fetchBookDetails = async () => {
			const res = await fetch(`${mainApi}/api/books/${params.bookId}/details`, {
				method: 'GET',
				headers: {
					lang: langState,
				},
			});
			const data = await res.json();
			setBookDetails(data);
			setLoading(false);
		};
		fetchBookDetails();
	}, [langState, params.bookId]);

	return (
		<div>
			{loading ? (
				<Spinner />
			) : (
				<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
					<Meta title={`iLaw | ${bookDetails.data.name}`} />
					<ShowBookDetails details={bookDetails} />
				</div>
			)}
		</div>
	);
};

export default BookDetails;
