import React from 'react';
import CartDetails from '../Components/CartPageComponent/CartDetails/CartDetails';
import { useSelector } from 'react-redux';
import AuthForms from '../Components/CartPageComponent/authForms/AuthForms';
const Cart = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	const authState = useSelector((state) => state.loginSlice.isLogin);
	return <div dir={langState === 'en' ? 'ltr' : 'rtl'}>{authState ? <CartDetails /> : <AuthForms />}</div>;
};

export default Cart;
