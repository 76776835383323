import React from 'react';
import BrwoseCategory from './BrowseCategory/BrwoseCategory';
import Icons from './Icons/Icons';
import SearchBar from './SearchBar/SearchBar';
import style from './SubHeader.module.css';
const SubHeader = () => {
	return (
		<>
			<div className={`d-none d-md-block ${style.mainContainer} w-100 `}>
				<div className='row align-items-center'>
					<div className='col-3'>
						<BrwoseCategory />
					</div>
					<div className='col-7'>
						<SearchBar />
					</div>
					<div className='col-2'>
						<Icons />
					</div>
				</div>
			</div>
			<div className={`d-block d-md-none w-100 ${style.mainContainer}`}>
				<div className='d-flex justify-content-between align-items-center mb-4'>
					<BrwoseCategory />
					<Icons />
				</div>
				<div className='w-100 px-3'>
					<SearchBar />
				</div>
			</div>
		</>
	);
};

export default SubHeader;
