import { configureStore } from '@reduxjs/toolkit';
import cartSlice from '../CartState/CartSlice';
import loginSlice from '../authState/loginSlice';
import langSlice from '../langState/langSlice';
import currencySlice from '../currencyState/currencySlice';
import CategorySlice from '../CategoryListState/CategoryListSlice';
import BooksSlice from '../AllBooks/BooksSlice';
import WhishlistSlice from '../Whishlist/WhishlistSlice';
import countriesSlice from '../Countries/Countries'
import citiesSlice from '../Cities/Cities'
const store = configureStore({
	reducer: {
		cartSlice,
		loginSlice,
		langSlice,
		currencySlice,
		CategorySlice,
		BooksSlice,
		WhishlistSlice,
		countriesSlice,
		citiesSlice,
	},
});
export default store;
