import React from 'react';
import { AiOutlineHeart } from 'react-icons/ai';
import { BsBagCheck, BsFillPersonFill } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import style from './Icons.module.css';
import CartSideBar from '../../../Cart/CartSideBar/CartSideBar';
import { openCart } from '../../../../Redux/CartState/CartSlice';
import { openForm } from '../../../../Redux/authState/loginSlice.js';
import AuthSideBar from '../../AuthSideBar/AuthSideBar';
import { useNavigate } from 'react-router-dom';
const Icons = () => {
	const state = useSelector((state) => state.cartSlice);
	const isFormOpen = useSelector((state) => state.loginSlice.openForm);
	const login = useSelector((state) => state.loginSlice.isLogin);
	const whisListItems = useSelector((state) => state.WhishlistSlice.whishListItems);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleAccountDetails = () => {
		if (login) {
			navigate('/accountdetails');
		} else {
			dispatch(openForm(true));
		}
	};
	const handleWhishListDetails = () => {
		if (login) {
			navigate('/wishlist');
		} else {
			dispatch(openForm(true));
		}
	};
	return (
		<>
			<div className={style.mainContainer}>
				{/* like qty*/}
				<div className={`${style.likeContainer} position-relative`}>
					<AiOutlineHeart className={style.icons} onClick={handleWhishListDetails} />
					<span className={style.likeQty}>{whisListItems.length}</span>
				</div>
				<div className='position-relative'>
					<BsFillPersonFill className={style.icons} onClick={handleAccountDetails} />
					{/*overlay*/}
					{isFormOpen ? <div className={style.overLay}></div> : ''}
					{isFormOpen ? <AuthSideBar /> : ''}
				</div>

				<div className='position-relative'>
					<BsBagCheck className={style.icons} onClick={() => dispatch(openCart())} />
					<span className={style.cartQty}>{state.cartItems.length}</span>
				</div>
				{/*overlay*/}
				{state.openCart ? <div className={style.overLay}></div> : ''}
				<CartSideBar />
			</div>
		</>
	);
};

export default Icons;
