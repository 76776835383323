import React from 'react';
import { useSelector } from 'react-redux';
import { navTitle } from '../../../../Fakers/Data.js';
import { NavLink, Link } from 'react-router-dom';
import style from './NavTitle.module.css';
const NavTitle = () => {
	const langState = useSelector((state) => state.langSlice.lang);

	return (
		<ul dir={langState === 'en' ? 'ltr' : 'rtl'} className={`row gap-2`}>
			{navTitle.map((item, index) =>
				item.title === 'Shop' || item.arabicTitle === 'المتجر' ? (
					<li key={index} className='dropdown col-2'>
						<NavLink className={`dropdown-toggle ${style.navLinks}`} data-bs-toggle='dropdown' aria-expanded='false'>
							{langState === 'en' ? item.title : item.arabicTitle}
						</NavLink>
						<ul class='dropdown-menu'>
							<li>
								<Link className='dropdown-item' to='/books'>
									{langState === 'en' ? 'By Books' : 'بواسطة الكتاب'}
								</Link>
							</li>
							<li>
								<Link className='dropdown-item' to='/publisher'>
									{langState === 'en' ? 'By Vendors / publishers' : 'بواسطة الناشرين'}
								</Link>
							</li>
							<li>
								<Link className='dropdown-item' to='/writer'>
									{langState === 'en' ? 'By Authors' : 'بواسطة المؤلفين'}
								</Link>
							</li>
						</ul>
					</li>
				) : (
					<li className='col-2' key={index}>
						<NavLink
							style={({ isActive }) => ({
								color: isActive ? 'red' : '',
								borderBottom: isActive ? '2px solid red' : '',
							})}
							className={`${style.navLinks}`}
							to={item.path}
						>
							{langState === 'en' ? item.title : item.arabicTitle}
						</NavLink>
					</li>
				)
			)}
		</ul>
	);
};

export default NavTitle;
