import React from 'react';
import style from './SocialMedia.module.css';
import { AiFillFacebook, AiFillTwitterSquare, AiOutlineWhatsApp, AiFillYoutube } from 'react-icons/ai';
import { FaTiktok } from 'react-icons/fa';
import { BsSnapchat } from 'react-icons/bs';
const SocialMedia = () => {
	return (
		<div className='text-center my-5'>
			<h5 className='mb-5'>Social Media</h5>
			<div className='my-5 d-flex justify-content-center align-items-center gap-3'>
				<a href='www.facebook.com' target='_blank'>
					<AiFillFacebook size={40} className={style.facebook} />
				</a>
				<a href='www.whatsapp.com' target='_blank' className={style.whats}>
					<AiOutlineWhatsApp size={40} />
				</a>
				<a href='wwww.twiiter.com' target='_blank' className={style.twitter}>
					<AiFillTwitterSquare size={40} />
				</a>
				<a href='www.youtube.com' target='_blank' className={style.youtube}>
					<AiFillYoutube size={40} />
				</a>
				<a href='www.tiktok.com' target='_blank' className={style.tiktok}>
					<FaTiktok size={40} />
				</a>
				<a href='www.snapchat.com' target='_blank' className={style.snap}>
					<BsSnapchat size={40} />
				</a>
			</div>
		</div>
	);
};

export default SocialMedia;
