export const navTitle = [
	{
		title: 'Home',
		arabicTitle: 'الرئيسية',
		path: '/',
	},
	{
		title: 'Shop',
		arabicTitle: 'المتجر',
		path: '/shop',
	},
	{
		title: 'About',
		arabicTitle: 'من نحن',
		path: '/about',
	},
	{
		title: 'FAQ',
		arabicTitle: 'التعليمات',
		path: '/faq',
	},
	{
		title: 'contact',
		arabicTitle: 'اتصل بنا',
		path: '/contact',
	},
];
