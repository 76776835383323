import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ShowWriterAndPublishers from './ShowWriterAndPublisher/ShowWriterAndPublishers';
const GetAllPublisherAndWriters = ({ apiPath , pagePath }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const langState = useSelector((state) => state.langSlice.lang);
	
	const mainApi = 'https://dashboard.ilawfair.com';
	useEffect(() => {
		const getData = async () => {
			const res = await fetch(`${mainApi}/api/${apiPath}`, {
				method: 'GET',
				headers: {
					lang: langState,
				},
			});
			const data = await res.json();
			setData(data.data);
			setLoading(false);
		};
		getData();
	}, [langState]);
  return (
		<div>
			<ShowWriterAndPublishers data={data} pagePath={pagePath} />
		</div>
  );
};

export default GetAllPublisherAndWriters;
