import React, { useState } from 'react';
import style from './Copon.module.css';
// down
import { MdKeyboardArrowDown } from 'react-icons/md';
// top
import { MdKeyboardArrowUp } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const Copon = () => {
	const [openCopon, setOpenCopon] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const cartItemsState = useSelector((state) => state.cartSlice.cartItems);
	const totalPrice = cartItemsState.reduce((acc, products) => {
		acc += products.price * products.quantity;
		return acc;
	}, 0);
	const discount = 100;
	function handleInputChange(event) {
		setInputValue(event.target.value);
	}
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className={`my-5 ${style.coponContainer}`}>
			<div className='p-3'>
				<div className='d-flex justify-content-between align-items-center'>
					<p className='fw-bold'>Copon</p>
					{openCopon ? <MdKeyboardArrowUp onClick={() => setOpenCopon(false)} size={30} /> : <MdKeyboardArrowDown onClick={() => setOpenCopon(true)} size={30} />}
				</div>
				{openCopon ? (
					<div className={`d-flex justify-content-between align-items-center ${style.applyCopon}`}>
						<input value={inputValue} onChange={handleInputChange} type='text' className={style.coponInp} placeholder='Copon Code' />
						<button disabled={inputValue.length === 0} className={style.coponBtn}>
							Apply Copon
						</button>
					</div>
				) : (
					''
				)}
				{/*reset container*/}
			</div>
			<div className={`my-5 ${style.resetContainer}`}>
				<div className='d-flex justify-content-between align-items-center'>
					<p>Total</p>
					<p>{totalPrice}</p>
				</div>
				<div className='d-flex justify-content-between align-items-center'>
					<p>Discount</p>
					<p>{discount}</p>
				</div>
				<div className='d-flex justify-content-between align-items-center'>
					<p>Net Price</p>
					<p>{totalPrice - discount}</p>
				</div>
			</div>
			<Link to='/checkout'>
				<button className={`${style.mainBtn} mb-5`}>Next</button>
			</Link>
		</div>
	);
};

export default Copon;
