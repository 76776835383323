import React from 'react';
import style from './TrackOrder.module.css';
import { CiLocationOn } from 'react-icons/ci';
import { useSelector } from 'react-redux';
const TrackOrder = () => {
	const lang = useSelector((state) => state.langSlice.lang);
	return (
		<div className={`d-none d-md-block ${style.drop}`}>
			<CiLocationOn /> {lang === 'en' ? 'Track your order' : 'تتبع طلبك'}
		</div>
	);
};

export default TrackOrder;
