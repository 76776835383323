import React from 'react';
import logo from '../../../assets/logo.3d3ebf04.png';
import NavTitle from './navTitle/NavTitle.jsx';
import ContactSection from './ContactSection/ContactSection';
import style from './MainHeader.module.css';
import { useSelector } from 'react-redux';
const MainHeader = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className={`container-fluid row  py-4`}>
			{/* logo*/}
			<div className='col-12 col-md-2 mb-4 mb-md-0 text-center text-md-start'>
				<img src={logo} alt='logo/img' className={`w-50 img-fluid ${style.logo}`} />
			</div>
			{/* navTitle*/}
			<div className='d-none d-md-block col-5'>
				<NavTitle />
			</div>
			{/*contact section*/}
			<div className=' col-12 col-md-5'>
				<ContactSection />
			</div>
		</div>
	);
};

export default MainHeader;
