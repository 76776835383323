import React, { useEffect, useRef } from 'react';
import style from './CartSideBar.module.css';
import { useSelector, useDispatch } from 'react-redux';
import CartCounter from '../CartCounter/CartCounter';
import CartEmpty from '../CartEmpty/CartEmpty';
import CartItems from '../CartItems/CartItems';
import { Link } from 'react-router-dom';
import { closeCart } from '../../../Redux/CartState/CartSlice';
const CartSideBar = () => {
	const cartSideBar = useRef(null);
	const currencyState = useSelector((state) => state.currencySlice.currency);
	const state = useSelector((state) => state.cartSlice);
	const dispatch = useDispatch();
	const totalPrice = state.cartItems.reduce((acc, book) => {
		acc += book.quantity * book.price;
		return acc;
	}, 0);

	// useEffect(() => {
	// 	const handleOutSideClick = (e) => {
	// 		if (cartSideBar.current && !cartSideBar.current.contains(e.target)) {
	// 			dispatch(closeCart());
	// 		}
	// 	};
	// 	if (state.openCart) {
	// 		document.addEventListener('mousedown', handleOutSideClick);
	// 	}
	// 	return () => {
	// 		document.removeEventListener('mousedown', handleOutSideClick);
	// 	};
	// }, [dispatch, state.openCart]);
	return (
		<div className={`${style.mainContainer} ${state.openCart ? style.show : style.hide}`}>
			<CartCounter />
			{state.cartItems.length > 0 ? <CartItems items={state.cartItems} /> : <CartEmpty />}
			{state.cartItems.length > 0 ? (
				<div className={`${style.subTotalContainer} my-4 p-4`}>
					{/* sub total details*/}
					<div className='d-flex justify-content-between align-items-center mb-5'>
						<p className='fw-bold ps-2'>Subtoal</p>
						<p className='pe-2'>
							{totalPrice} {currencyState}
						</p>
					</div>

					<Link to='/cart'>
						<button onClick={() => dispatch(closeCart(false))} className={style.viewCart}>
							View Cart
						</button>
					</Link>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default CartSideBar;
