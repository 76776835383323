import React from 'react';

import style from './Carousel.module.css';
const Carousel = () => {
	return (
		<div id='carouselExampleRide' className='carousel slide' data-bs-ride='true'>
			<div className='carousel-inner'>
				<div className={`carousel-item active ${style.mainContainer} d-flex justify-content-center align-items-center`}>
					<h2 className='text-uppercase fs-3'>Bermanent electronic books</h2>
				</div>
			</div>
			<button className='carousel-control-prev' type='button' data-bs-target='#carouselExampleRide' data-bs-slide='prev'>
				<span className='carousel-control-prev-icon' aria-hidden='true'></span>
				<span className='visually-hidden'>Previous</span>
			</button>
			<button className='carousel-control-next' type='button' data-bs-target='#carouselExampleRide' data-bs-slide='next'>
				<span className='carousel-control-next-icon' aria-hidden='true'></span>
				<span className='visually-hidden'>Next</span>
			</button>
		</div>
	);
};

export default Carousel;
/*
  	<div classNameNameName={`container-fluid  ${style.mainContainer} mb-5`}>
			<Slider {...settings} classNameNameName = {style.slider}>
				<div classNameNameName={` ${style.sliderData}`}>
					<h2>Bermanent electronic books</h2>
				</div>
				<div>
					<h2>Bermanent electronic books</h2>
				</div>
			</Slider>
		</div>
*/
