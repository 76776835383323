import React, { useState } from 'react';
import style from './SelectLang.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeLang } from '../../../../Redux/langState/langSlice.js';

const SelectLang = () => {
	const dispatch = useDispatch();
	const lang = useSelector((state) => state.langSlice.lang);
	const [value, setValue] = useState(lang);

	const handleChangeLang = (val) => {
		setValue(val);
		dispatch(changeLang(val.toLocaleLowerCase()));
	};
	return (
		<div className='dropdown'>
			<p className={`dropdown-item dropdown-toggle ${style.drop}`} data-bs-toggle='dropdown' aria-expanded='false'>
				{value === 'ar' ? 'العربية' : value}
			</p>
			<ul className='dropdown-menu'>
				<li onClick={() => handleChangeLang('ar')} className={`dropdown-item ${style.drop}`}>
					{lang === 'en' ? 'Ar' : 'العربية'}
				</li>
				<li onClick={() => handleChangeLang('en')} className={`dropdown-item ${style.drop}`}>
					{lang === 'en' ? 'EN' : 'الإنجليزية'}
				</li>
				<li onClick={() => handleChangeLang('fr')} className={`dropdown-item ${style.drop}`}>
					{lang === 'en' ? 'FR' : 'الفرنسية'}
				</li>
			</ul>
		</div>
	);
};

export default SelectLang;
