import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	cities: [],
};
const citiesSlice = createSlice({
	name: 'citiesSlice',
	initialState,
	reducers: {
		addCities: (state, action) => {
			state.countries = action.payload;
		},
	},
});
export const { addCities } = citiesSlice.actions;
export default citiesSlice.reducer;
