import React from 'react';
import logo from '../../../assets/logo.3d3ebf04.png';
import style from './Footer.module.css';
import { AiOutlineMail } from 'react-icons/ai';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Footer = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	const categories = useSelector((state) => state.CategorySlice.categoryItems);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='container-fluid'>
			{/* upper footer*/}

			<div className={`${style.upperFooter}  row  gap-2`}>
				{/* logo and contact section*/}
				<div className='col-12 col-md-3 text-center text-md-start'>
					{/* logo*/}

					<img src={logo} alt='' className={`mb-3 ${style.logo}`} />
					{/*contact section*/}
					<ul>
						<li className='mb-2'>
							<a href='mailto:info@ilawfair.com' target='_blank' className={`${style.navIcons} ${style.info}`}>
								<AiOutlineMail /> info@ilawfair.com
							</a>
						</li>
						<li>
							<a href='tel:971564093620' target='_blank' className={`${style.navIcons} ${style.info}`}>
								<BsFillTelephoneFill /> 971564093620
							</a>
						</li>
					</ul>
				</div>
				{/* explore section*/}
				<div className='col-12 col-md-3 text-center text-md-start'>
					<h5 className='mb-2'>explore</h5>
					<ul>
						<li className='mb-2'>
							<Link className={style.links} to='/'>
								home
							</Link>
						</li>
						<li className='mb-2'>
							<Link className={style.links} to='/'>
								shop
							</Link>
						</li>
						<li className='mb-2'>
							<Link className={style.links} to='/faq'>
								FAQ
							</Link>
						</li>
						<li className='mb-2'>
							<Link className={style.links} to='/'>
								sign in / join
							</Link>
						</li>
					</ul>
				</div>
				{/*customer services section*/}
				<div className='col-12 col-md-2 text-center text-md-start'>
					<h5 className='mb-2'>customer service</h5>
					<ul>
						<li className='mb-2'>
							<Link className={style.links} to='/about'>
								about
							</Link>
						</li>
						<li className='mb-2'>
							<Link className={style.links} to='/contact'>
								contact
							</Link>
						</li>
					</ul>
				</div>
				{/* category section*/}
				<div className='col-12 col-md-3 text-center text-md-end'>
					<h5 className='mb-4 text-center'>categories</h5>
					<ul className={`d-flex flex-column align-items-center justify-content-center w-100 ms-auto ${style.categoriesContainer}`}>
						{categories.map((category, index) => (
							<li key={index} className={`mb-2 ${style.links}`}>
								{langState === 'en' ? category.name_en : category.name_ar}
							</li>
						))}
					</ul>
				</div>
			</div>
			{/*lower footer*/}
			<div className='container-fluid d-flex justify-content-between align-items-center py-4'>
				<p>All rights reservedilaw ©2023</p>
				<p className={style.techno}>Powered by Technomasr</p>
			</div>
		</div>
	);
};

export default Footer;
