import React, { useState } from 'react';
import style from './selectCurrency.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeCurrency } from '../../../../Redux/currencyState/currencySlice.js';
const SelectCurrency = () => {
	const dispatch = useDispatch();
	const lang = useSelector((state) => state.langSlice.lang);
	const currency = useSelector((state) => state.currencySlice.currency);
	const [value, setValue] = useState(currency);

	const handleChangeCurrency = (v) => {
		setValue(v);
		dispatch(changeCurrency(v));
	};
	return (
		<div className='dropdown'>
			<p className={`dropdown-item dropdown-toggle ${style.drop}`} data-bs-toggle='dropdown' aria-expanded='false'>
				{lang === 'ar' ? 'دولار أمريكي' :  value }
			</p>
			<ul className='dropdown-menu'>
				<li onClick={() => handleChangeCurrency('USD')} className={`dropdown-item ${style.drop}`}>
					{lang === 'en' ? 'USD' : 'دولار امريكي'}
				</li>
				<li onClick={() => handleChangeCurrency('SR')} className={`dropdown-item ${style.drop}`}>
					{lang === 'en' ? 'SR' : 'ريال سعودي'}
				</li>
				<li onClick={() => handleChangeCurrency('L.E')} className={`dropdown-item ${style.drop}`}>
					{lang === 'en' ? 'L.E' : 'جنيه مصري'}
				</li>
			</ul>
		</div>
	);
};

export default SelectCurrency;
