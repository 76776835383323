import React from 'react';
import { useSelector } from 'react-redux';

import Meta from '../Components/Meta/Meta.jsx';
import Title from '../Utilits/Title/Title.jsx';
import GetAllPublisherAndWriters from '../Components/WriterAndPublisherPages/GetAllPublisherAndWriters.jsx';

const Publishers = () => {
	const langState = useSelector((state) => state.langSlice.lang);

	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
			<Meta title='iLaw | Publishers' />
			<Title title='Publishers' />
			<GetAllPublisherAndWriters apiPath='publishers' pagePath='publisher' />
		</div>
	);
};

export default Publishers;
