import React from 'react';
import Title from '../Utilits//Title/Title.jsx';
import Meta from '../Components/Meta/Meta.jsx';
import { useSelector } from 'react-redux';
import ShowAboutDetails from '../Components/AboutPageComponent/ShowAboutDetails.jsx';
const About = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir= {langState === 'en' ? 'ltr' : 'rtl'} className='my-5'>
			<Title title={langState === 'en' ? 'About Us' : 'من نحن'} />
      <Meta title={langState === 'en' ? 'iLaw | About Us' : 'iLaw |من نحن'} />
      <ShowAboutDetails /> 
		</div>
	);
};

export default About;
