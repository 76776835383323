import React from 'react';
import style from './ShopMenu.module.css';
import { MdArrowBackIosNew } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeCategory } from '../../../../../../Redux/CategoryListState/CategoryListSlice.js';
const ShopMenu = ({ setOpenPagseMenu, setOpenShopMenu }) => {
	const dispatch = useDispatch();
	const langState = useSelector((state) => state.langSlice.lang);
	const navigate = useNavigate();
	return (
		<div>
			<div
				onClick={() => {
					setOpenPagseMenu(true);
					setOpenShopMenu(false);
				}}
				className={`${style.listContainer} my-2 py-1`}
			>
				<div className={`p-3  ${style.list}`}>
					<MdArrowBackIosNew /> {langState === 'en' ? 'Back' : 'الرجوع'}
				</div>
			</div>
			<div
				onClick={() => {
					navigate('/');
					dispatch(closeCategory());
				}}
				className={`${style.listContainer} my-2 py-1`}
			>
				<Link to='/shop' className={`p-3  ${style.list} ${style.link}`}>
					{langState === 'en' ? 'By Books' : 'بواسطة الكتب'}
				</Link>
			</div>

			<div
				onClick={() => {
					navigate('/publisher');
					dispatch(closeCategory());
				}}
				className={`${style.listContainer} my-2 py-1`}
			>
				<Link to='/publisher' className={`p-3  ${style.list} ${style.link}`}>
					{langState === 'en' ? 'By Publishers' : 'بواسطة الناشرين'}
				</Link>
			</div>
			<div
				onClick={() => {
					navigate('/writer');
					dispatch(closeCategory());
				}}
				className={`${style.listContainer} my-2 py-1`}
			>
				<Link to='/writer' className={`p-3  ${style.list} ${style.link}`}>
					{langState === 'en' ? 'By Writers' : 'بواسطة المؤلفين'}
				</Link>
			</div>
			
		</div>
	);
};

export default ShopMenu;
