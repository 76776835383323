import React from 'react';
import ShowContactDetails from '../Components/ContactPageComponent/ShowContactDetails/ShowContactDetails';
import Title from '../Utilits//Title/Title.jsx';
import Meta from '../Components/Meta/Meta.jsx';
import { useSelector } from 'react-redux';
const Contact = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
			<Title title={langState === 'en' ? 'Contact Us' : 'اتصل بنا'} />
			<Meta title={langState === 'en' ? 'iLaw | Contact Us' : 'iLaw |اتصل بنا '} />
			<ShowContactDetails />
		</div>
	);
};

export default Contact;
