import React, { useState } from 'react';
import style from './Tabs.module.css';
const Tabs = ({ children }) => {
	const [selectedTab, setSelectedTab] = useState(0);
	console.log(children[selectedTab].props.children.props.children);
	const onTabClick = (index) => {
		setSelectedTab(index);
	};
	return (
		<div>
			<ul className={`d-flex justify-content-center align-items-center gap-5 flex-wrap py-4 ${style.tabContainer}`}>
				{children.map((tab, index) => (
					<li key={index} onClick={() => onTabClick(index)} className={`${style.tabTitle}${selectedTab === index ? style.active : ''}`}>
						{tab.props.title}
					</li>
				))}
			</ul>
			{children[selectedTab].props.children.props.children}
		</div>
	);
};

export default Tabs;
