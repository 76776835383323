import React from 'react';
import style from './ShowWritersAndPublishers.module.css';
import { Link } from 'react-router-dom';
const ShowWriterAndPublishers = ({ data, pagePath }) => {
	console.log(data);
	return (
		<div className='container-fluid pb-5'>
			<div className='row'>
				{data.map((item, index) => {
					return (
						<div key={index} className='col-6 col-md-3 col-lg-2'>
							{/*img container*/}
							<div>
								<Link to={`/${pagePath}/${item.id}`} className={`mb-4 ${style.imgContainer}`}>
									<img src={item.photo} alt='' className={`${style.mainImg} mb-4`} />
								</Link>
								<Link className= {style.name} to={`/${pagePath}/${item.id}`}>
									<h4 className='text-center'>{item.name}</h4>
								</Link>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ShowWriterAndPublishers;
