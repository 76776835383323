import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Meta from '../Meta/Meta';
import Spinner from '../Spinner/Spinner';
import ShowPublisherAndWriterDetails from './ShowPublishersAndWritersDetails/ShowPublisherAndWriterDetails';
const GetPublishersAndWritersDetails = ({ apiPath, id, endPoint }) => {
	const mainApi = 'https://dashboard.ilawfair.com';
	const langState = useSelector((state) => state.langSlice.lang);
	const [loading, setLoading] = useState(true);
	const [details, setDetails] = useState({});

	useEffect(() => {
		const fetchPublisherDetails = async () => {
			const res = await fetch(`${mainApi}/api/${apiPath}/${id}/details`, {
				method: 'GET',
				headers: {
					lang: langState,
				},
			});
			const data = await res.json();
			setDetails(data.data);
			setLoading(false);
		};
		fetchPublisherDetails();
	}, [langState, apiPath, id]);

	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<div>
					<Meta title={details.name} />
					<ShowPublisherAndWriterDetails details={details} endPoint={endPoint} />
				</div>
			)}
		</>
	);
};

export default GetPublishersAndWritersDetails;
