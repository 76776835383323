import React from 'react';
import { useSelector } from 'react-redux';

import BookInfo from './BookInfo/BookInfo';
const ShowBookDetails = ({ details }) => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='container-fluid py-5'>
			<BookInfo
				details={details.data}
				isModal = {false}
			/>
		</div>
	);
};

export default ShowBookDetails;
