import React from 'react';
import style from './CartEmpty.module.css';
import { useDispatch } from 'react-redux';
import emptyBag from '../../../assets/emptybag.png';
import { useNavigate } from 'react-router-dom';
import { closeCart } from '../../../Redux/CartState/CartSlice';
const CartEmpty = () => {
	const dispatch = useDispatch();
	const navegate = useNavigate();
	return (
		<>
			<div className='d-flex justify-content-center align-items-center h-100'>
				<div>
					<img src={emptyBag} alt='' className={style.mainImg} />
					<button
						onClick={() => {
							navegate('/');
							dispatch(closeCart());
						}}
						className={style.backToHome}
					>
						Back to I Law
					</button>
				</div>
			</div>
		</>
	);
};

export default CartEmpty;
