import React, { useState } from 'react';
import style from './ShowFaqDetails.module.css';
// down
import { MdKeyboardArrowDown } from 'react-icons/md';
// top
import { MdKeyboardArrowUp } from 'react-icons/md';
import { useSelector } from 'react-redux';
const ShowFaqDetails = ({ details, htmlContent }) => {
	const [showSubscription, setShowSubscription] = useState(false);
	const [showSellBook, SetShowSellBokk] = useState(false);
  const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className={`container ${style.faqContainer}`}>
			<div className='p-3'>
				<div className={`d-flex justify-content-between align-items-center pb-2 $`}>
					<p className='fw-bold fs-6'>{details[0].question}</p>
					{showSubscription ? <MdKeyboardArrowUp size={25} onClick={() => setShowSubscription(false)} /> : <MdKeyboardArrowDown onClick={() => setShowSubscription(true)} size={25} />}
				</div>
				{showSubscription ? <p dangerouslySetInnerHTML={{ __html: details[0].answer }}></p> : ''}
			</div>
			<div className={style.sellContainer}>
				<div className='p-3'>
					<div className={`d-flex justify-content-between align-items-center pb-2 $`}>
						<p className='fw-bold fs-6'>{details[1].question}</p>
						{showSellBook ? <MdKeyboardArrowUp size={25} onClick={() => SetShowSellBokk(false)} /> : <MdKeyboardArrowDown onClick={() => SetShowSellBokk(true)} size={25} />}
					</div>
					{showSellBook ? <p dangerouslySetInnerHTML={{ __html: details[1].answer }}></p> : ''}
				</div>
			</div>
		</div>
	);
};

export default ShowFaqDetails;
