import React from 'react';
import style from './CartTable.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { increaseQTY, decreaseQTY, removeFromCart } from '../../../../Redux/CartState/CartSlice';
import { Link } from 'react-router-dom';
const CartTable = () => {
	const cartItems = useSelector((state) => state.cartSlice.cartItems);
	const currencyState = useSelector((state) => state.currencySlice.currency);
	console.log(cartItems);
	const dispatch = useDispatch();
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='my-5 container'>
			<table class='table'>
				<thead>
					<tr>
						<th scope='col'>Image</th>
						<th scope='col'>Book</th>
						<th scope='col'>Price</th>
						<th scope='col'>Quantity</th>
						<th scope='col'>Total</th>
						<th scope='col'>Action</th>
					</tr>
				</thead>
				<tbody>
					{cartItems.map((item, index) => (
						<tr key={index}>
							<td className={style.row}>
								<img className={style.img} src={item.img} alt='' />
							</td>
							<td className={style.row}>{item.name}</td>
							<td className={style.row}>
								<p className='mb-1'>
									{item.price} {currencyState}
								</p>
							</td>
							<td className={`d-flex flex-column flex-sm-row align-items-center justify-content-center gap-2 ${style.h}`}>
								<div className={style.box} onClick={() => dispatch(decreaseQTY(item))}>
									<AiOutlineMinus />
								</div>
								<div className={style.box}>
									<p>{item.quantity}</p>
								</div>
								<div className={style.box} onClick={() => dispatch(increaseQTY(item))}>
									<AiOutlinePlus />
								</div>
							</td>
							<td className={style.row}>
								<p className=''>
									{item.price * item.quantity} {currencyState}
								</p>
							</td>
							<td className={`${style.row}`}>
								<Link className={`${style.link} mb-2`} to={`/book/${item.id}`}>
									View
								</Link>
								<button onClick={() => dispatch(removeFromCart(item))} className={style.link}>
									Delete
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default CartTable;
