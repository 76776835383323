import React from 'react';
import style from './CartCounter.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import { closeCart, clearCart } from '../../../Redux/CartState/CartSlice';
const CartCounter = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	const state = useSelector((state) => state.cartSlice);
	const dispatch = useDispatch();
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'}>
			<div className='d-flex justify-content-between align-items-center p-2'>
				{/* close cart*/}
				<div className={` ${style.closeContainer}`} onClick={() => dispatch(closeCart())}>
					<AiOutlineClose />
				</div>
				{/* cart */}

				{/*clear cart*/}
				{state.cartItems.length > 0 ? (
					<div className={`${style.clear} `}>
						<p onClick={() => dispatch(clearCart())}>Clear your Cart</p>
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};

export default CartCounter;
