import React, { useState } from 'react';
import style from '../ResetPassword/ResetPassword.module.css';
import { BsPersonFill } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { closeForm } from '../../../../../Redux/authState/loginSlice';
import Swal from 'sweetalert2';
const ResetPassword = ({ isSideBar, setNewAccount, setLogin, setResetPassword }) => {
	const mainApi = 'https://dashboard.ilawfair.com';
	const lang = useSelector((state) => state.langSlice.lang);
	const [mail, setMail] = useState('');
	const [mailError, setMailError] = useState('');
	const dispatch = useDispatch();
	// - email value
	function handleEmailChange(event) {
		setMail(event.target.value);
		setMailError(validateEmail(event.target.value));
	}
	// email validation
	function validateEmail(value) {
		if (value.trim() === '') {
			return 'Email is required';
		}

		if (!/\S+@\S+\.\S+/.test(value)) {
			return 'Email is invalid';
		}

		return '';
	}
	// reset password
	const handleResetPassword = async () => {
		await fetch(`${mainApi}/api/sendResetCode`, {
			method: 'POST',
			headers: {
				lang,
				'Content-Type': 'application/json',
				'Accepted-lang': lang,
			},
			body: JSON.stringify({ email: mail }),
		})
			.then((data) => data.json())
			.then((res) => {
				if (res.status === 'success') {
					Swal.fire({
						position: 'top-end',
						icon: 'success',
						title: res.message,
						showConfirmButton: false,
						timer: 1500,
					});
					dispatch(closeForm());
				} else {
					Swal.fire({
						position: 'top-end',
						icon: 'success',
						title: res.message,
						showConfirmButton: false,
						timer: 1500,
					});
				}
			});
	};
	return (
		<div dir={lang === 'en' ? 'ltr' : 'rtl'}>
			<div className={`pb-2 d-flex justify-content-between align-items-center ${style.accountContainer} ${isSideBar ? 'd-block' : 'd-none'}`}>
				<div>
					<BsPersonFill size={30} />
					<span className='fw-bold'>{lang === 'en' ? 'Account' : 'الحساب'}</span>
				</div>
				<AiOutlineClose size={30} onClick={() => dispatch(closeForm(false))} className={style.closeContainer} />
			</div>
			{/**email*/}
			<div className='my-5'>
				<label htmlFor='email' className='d-block fw-bold'>
					{lang === 'en' ? 'Email Address' : 'البريد الإلكتروني'}
				</label>
				<input required onChange={handleEmailChange} type='email' id='email' className={`d-block ${style.inp} mb-3`} placeholder='Enter your Email' />
				{mailError && <div className={`my-2 ${style.error}`}>{mailError}</div>}
			</div>
			<button onClick={handleResetPassword} className={style.btn}>
				{lang === 'en' ? 'Reset Password' : 'استعادة كلمة المرور'}
			</button>
			<button
				onClick={() => {
					setLogin(true);
					setNewAccount(false);
					setResetPassword(false);
				}}
				className={style.logBtn}
			>
				{lang === 'en' ? 'login' : 'تسجيل الدخول'}
			</button>
		</div>
	);
};

export default ResetPassword;
