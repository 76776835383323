import React from 'react';
import ContactInformation from '../ContactInformations/ContactInformation';
import SocialMedia from '../SocialMedia/SocialMedia';
import style from './ShowContactDetails.module.css';
import ContactUsForm from '../ContactUsForm/ContactUsForm.jsx';
import { useSelector } from 'react-redux';
const ShowContactDetails = () => {
  const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div dir={langState === 'en' ? 'ltr' : 'rtl'} className={`container ${style.mainContainer} mb-5`}>
			<div className='p-3'>
				<ContactInformation />
				<SocialMedia />
				<ContactUsForm />
			</div>
		</div>
	);
};

export default ShowContactDetails;
