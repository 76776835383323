import React from 'react';
import style from './MainList.module.css';
import { IoIosArrowForward } from 'react-icons/io';
import { useSelector } from 'react-redux';
const MainList = ({ setOpenPagseMenu, setOpenCategoriesMenu, setOpenLanguagesMenu }) => {
	const langState = useSelector((state) => state.langSlice.lang);
	return (
		<div>
			<div onClick={() => setOpenPagseMenu(true)} className={`${style.listContainer} my-2 py-1`}>
				<div className={`p-3 d-flex justify-content-between ${style.list}`}>
					<p>{langState === 'en' ? 'Pages' : 'الصفحات'}</p>
					<IoIosArrowForward />
				</div>
			</div>
			<div onClick={() => setOpenCategoriesMenu(true)} className={`${style.listContainer} my-2 py-1`}>
				<div className={`p-3 d-flex justify-content-between ${style.list}`}>
					<p>{langState === 'en' ? 'Categories' : 'الأقسام'}</p>
					<IoIosArrowForward />
				</div>
			</div>
			<div onClick={() => setOpenLanguagesMenu(true)} className={`${style.listContainer} my-2 py-1`}>
				<div className={`p-3 d-flex justify-content-between ${style.list}`}>
					<p>{langState === 'en' ? 'Languages' : 'اللغات'}</p>
					<IoIosArrowForward />
				</div>
			</div>
		</div>
	);
};

export default MainList;
