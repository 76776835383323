import React from 'react'
import BooksTable from '../Components/Dashboard/BooksTable/BooksTable';
import DashBoardSideBar from '../Components/Dashboard/SideBar/DashBoardSideBar';
import Meta from '../Components/Meta/Meta.jsx';
import { useSelector } from 'react-redux';
const Books = () => {
  const langState = useSelector((state) => state.langSlice.lang);
  return (
    <>
			<Meta title='iLaw | Books' />
			<div dir={langState === 'en' ? 'ltr' : 'rtl'} className='container-fluid'>
				<div className='row gap-4  my-5'>
					<div className='col-12 col-md-3'>
						<DashBoardSideBar />
					</div>
					<div className='col-12 col-md-8'>
						<BooksTable /> 
					</div>
				</div>
			</div>
    </>
    )
}

export default Books
