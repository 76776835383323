import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';
import style from './CategorySettings.module.css';
import { closeCategory } from '../../../../../../Redux/CategoryListState/CategoryListSlice';
import { logOut } from '../../../../../../Redux/authState/loginSlice.js';
import { openForm } from '../../../../../../Redux/authState/loginSlice.js';
const CategorySettings = () => {
	const langState = useSelector((state) => state.langSlice.lang);
	const isLogin = useSelector((state) => state.loginSlice.isLogin);
	const dispatch = useDispatch();
	const handleLogin = () => {
		if (isLogin) {
			dispatch(logOut());
		}
		if (!isLogin) {
			dispatch(openForm(true));
		}
		dispatch(closeCategory());
	};
	return (
		<div className={`p-4 ${style.mainContainer}`}>
			<h5 className='text-uppercase mb-3'>{langState === 'en' ? 'help & settings' : 'الإعدادات'}</h5>
			<ul>
				<li className={`mb-3 ${style.link}`}>
					<Link onClick={() => dispatch(closeCategory())} to='/accountdetails' className={style.link}>
						{langState === 'en' ? 'Account' : 'الحساب'}
					</Link>
				</li>
				<li className={`mb-3 ${style.link}`}>
					<Link onClick={() => dispatch(closeCategory())} to='/faq' className={style.link}>
						{langState === 'en' ? 'help' : 'المساعدة'}
					</Link>
				</li>
				<li className={`${style.link} d-flex gap-2 align-items-center`}>
					<AiOutlineUser size={20} />
					<Link onClick={handleLogin} to='/' className={style.link}>
						{isLogin && langState === 'en'
							? 'Log out'
							: isLogin && langState !== 'en'
							? 'تسجيل الخروج'
							: !isLogin && langState === 'en'
							? 'Log in'
							: !isLogin && langState !== 'en'
							? 'تسجيل الخروج'
							: ''}
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default CategorySettings;
